import React, { useState } from "react";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { images } from "../../constants/ImageImports";
import CommonFormInput from "../common/formInputs/CommonFormInput ";
import { classnameValidation } from "../../utils/validation";
import { mdActions } from "../../redux/slices/modalSlice";
import usePost from "../../services/customHook/usePost";
import { APIConstant } from "../../services/apiConstant";
import { tempRefresh } from "../../redux/slices/tempSlice";

const addSchema = Yup.object().shape({
  ...classnameValidation.fields,
});

export const AddClass = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const postMutation = usePost();
  const isRefresh = useSelector((state) => state.temp.isRefresh);

  const handleSubmit = async (values, { resetForm }) => {
    setIsLoading(true);
    try {
      const result = await postMutation.mutate({
        url: APIConstant?.CLASS_CREATE,
        payload: {
          class_title: values?.classname,
        },
      });
      setTimeout(() => {
        resetForm();
        dispatch(tempRefresh(!isRefresh));
        dispatch(mdActions.hideModal());
      }, 1000);
    } catch (error) {
      console.error("Error making profile API call:", error);
    }
  };

  const closeModal = () => {
    dispatch(mdActions.hideModal());
  };

  return (
    <div className="mdl_inr addClass_mdl">
      <div className="cross_icon" onClick={closeModal}>
        <img src={images?.modelCross} alt="img" className="prft_img" />
      </div>
      <div className="mdl_hdr">
        <h4>Add New Class</h4>
      </div>
      <div className="mdl_ctnt">
        <Formik
          initialValues={{
            classname: "",
          }}
          validationSchema={addSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting }) => (
            <Form className="form_row">
              <CommonFormInput
                label="Class Name*"
                type="text"
                name="classname"
                placeholder="Enter Class Name"
              />
              <button className="btn_reset blue_btn" type="submit">
                {isLoading ? "Submitting.." : "Save"}
              </button>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};
