import React, { useState } from "react";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import { useDispatch } from "react-redux";
import { images } from "../../constants/ImageImports";
import CommonFormInput from "../common/formInputs/CommonFormInput ";
import { classnameValidation } from "../../utils/validation";
import { mdActions } from "../../redux/slices/modalSlice";
import { APIConstant } from "../../services/apiConstant";
import usePost from "../../services/customHook/usePost";
import Loader from "../common/loader";

const addSchema = Yup.object().shape({
  ...classnameValidation.fields,
});
export const EditClass = ({ setClose, data, setApiRefersh, refresh }) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const postMutation = usePost();

  const handleSubmit = async (values, { resetForm }) => {
    try {
      setIsLoading(true);
      const result = await postMutation.mutate({
        url: APIConstant?.CLASS_UPDATE,
        payload: {
          id: data?.id,
          class_title: values?.classname,
        },
      });
      setTimeout(() => {
        resetForm();
        setApiRefersh(!refresh);
        setIsLoading(false);
        setClose(false);
        dispatch(mdActions.hideModal());
      }, 1000);
    } catch (error) {
      setIsLoading(false);
      console.error("Error making profile API call:", error);
    }
  };

  const closeModal = () => {
    dispatch(mdActions.hideModal());
    setClose(false);
  };
  return (
    <div className="mdl_inr addClass_mdl">
      <div className="cross_icon" onClick={closeModal}>
        <img src={images?.modelCross} alt="img" className="prft_img" />
      </div>
      <div className="mdl_hdr">
        <h4>Edit Class</h4>
      </div>
      <div className="mdl_ctnt">
        <Formik
          initialValues={{
            classname: data?.name || "",
          }}
          validationSchema={addSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, values, dirty }) => (
            <Form className="form_row">
              <CommonFormInput
                label="Edit Class Name"
                type="text"
                name="classname"
                placeholder="Enter Classname*"
              />
              <button
                className="btn_reset blue_btn"
                type="submit"
                disabled={!dirty}
              >
                {isLoading ? "Submitting.." : "Save"}
              </button>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};
