import React from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

export const ClassSelectBox = ({
  value,
  setValue,
  data,
  className,
  label,
  placeholder,
  error, 
  setError
}) => {
  const handleChange = (event) => {
    setValue(event.target.value);
    setError(false)
  };

  return (
    <FormControl
      className={className ? `${className} select__bx` : "select__bx"}
    >
      {label && <label>{label}</label>}
      <Select
        value={value}
        onChange={handleChange}
        displayEmpty
        aria-multiselectable
        renderValue={(selected) => {
          if (selected.length === 0) {
            return <span>{placeholder}</span>;
          }

          return selected;
        }}
        inputProps={{ "aria-label": "Without label" }}
      >
        {data?.map((item) => {
          return (
            <MenuItem value={item?.class_title} name={item?.class_title} key={item?._id}>
              {item?.class_title}
            </MenuItem>
          );
        })}
      </Select>
      {error && <div className='error_text'>Thise Field is required</div>}
    </FormControl>
  );
};
