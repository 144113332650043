import React, { useState } from "react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import usePost from "../../services/customHook/usePost";
import { APIConstant } from "../../services/apiConstant";
import { changePasswordValidation } from "../../utils/validation";
import { images } from "../../constants/ImageImports";
import Loader from "../../components/common/loader";
import { toast } from "react-toastify";

export const ChangePassword = () => {
  const postMutation = usePost();
  const [showOld, setShowOld] = useState(false);
  const [showNew, setShowNew] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [loading, setLoading] = useState(false);

  const passwordSchema = Yup.object().shape({
    ...changePasswordValidation.fields,
  });

  const handlePostProfileData = async (
    values,
    { setSubmitting, resetForm }
  ) => {
    if (values?.currentPassword === values?.password) {
      toast.error("Old Password and New Password cannot be same");
    } else {
      setLoading(true);
      try {
        await postMutation.mutate({
          url: APIConstant?.CHANGE_PASSWORD,
          payload: {
            old_password: values?.currentPassword,
            new_password: values?.password,
            confirm_password: values?.confirmPassword,
          },
          isToastDiasble: false,
        });

        const responseData = postMutation.data;
        // toast.success("Password updated successfully");

        resetForm();
      } catch (error) {
        setLoading(false);
      } finally {
        setLoading(false);

        setSubmitting(false);
      }
    }
  };

  return (
    <>
      {loading && <Loader />}
      <div className="tab_wpr">
        <div className="tab_hdg_wpr">
          <h5 className="tab_hdg">Change Password</h5>
        </div>
        <Formik
          initialValues={{
            currentPassword: "",
            password: "",
            confirmPassword: "",
          }}
          validationSchema={passwordSchema}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            handlePostProfileData(values, { setSubmitting, resetForm });
            setSubmitting(false);
          }}
        >
          {({ isSubmitting, resetForm }) => (
            <Form className="save_btn">
              <div className="prf_fields edit_fields edit_fields-3 mt-40">
                <div className="prf_field_Item">
                  <div className="prf_label">Old Password</div>
                  <div className="prf_text">
                    <div className="input_field icon">
                      <Field
                        type={showOld ? "text" : "password"}
                        name="currentPassword"
                        placeholder="Enter Old Password"
                      />
                      <img
                        className="cursor_pointer"
                        src={
                          showOld
                            ? images?.passwordInputUnLock
                            : images?.passwordInputLock
                        }
                        alt="img"
                        onClick={() => setShowOld(!showOld)}
                      />
                    </div>
                    <ErrorMessage
                      name="currentPassword"
                      component="div"
                      className="error_msg"
                    />
                  </div>
                </div>
                <div className="prf_field_Item">
                  <div className="prf_label">New Password</div>
                  <div className="prf_text">
                    <div className="input_field icon">
                      <Field
                        type={showNew ? "text" : "password"}
                        name="password"
                        placeholder="Enter New Password"
                      />
                      <img
                        className="cursor_pointer"
                        src={
                          showNew
                            ? images?.passwordInputUnLock
                            : images?.passwordInputLock
                        }
                        alt="img"
                        onClick={() => setShowNew(!showNew)}
                      />
                    </div>
                    <ErrorMessage
                      name="password"
                      component="div"
                      className="error_msg"
                    />
                  </div>
                </div>
                <div className="prf_field_Item">
                  <div className="prf_label">Confirm Password</div>
                  <div className="prf_text">
                    <div className="input_field icon">
                      <Field
                        type={showConfirm ? "text" : "password"}
                        name="confirmPassword"
                        placeholder="Enter Confirm Password"
                      />
                      <img
                        className="cursor_pointer"
                        src={
                          showConfirm
                            ? images?.passwordInputUnLock
                            : images?.passwordInputLock
                        }
                        alt="img"
                        onClick={() => setShowConfirm(!showConfirm)}
                      />
                    </div>
                    <ErrorMessage
                      name="confirmPassword"
                      component="div"
                      className="error_msg"
                    />
                  </div>
                </div>
              </div>

              <button
                type="submit"
                className="pink_btn btn_reset"
                disabled={isSubmitting}
              >
                {isSubmitting ? "Updating..." : "Update Password"}
              </button>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};
