import React from "react";
import { images } from "../../constants/ImageImports";
import { Link } from "react-router-dom";
import { StudentTable } from "./StudentTable";
import { APIConstant } from "../../services/apiConstant";

export const StudentList = () => {
  return (
    <div className="student_list_page sec-padding">
      <div className="cstm_Ctnr">
        <div className="cmn_hdr">
          <h3>
            <b>Student List</b>
          </h3>
          <div className="btn_group">
            <Link to="/add-student" className="btn_reset pink_btn">
              <span className="me-10">
                <img src={images?.plus} alt="img" />
              </span>
              Add Student
            </Link>
          </div>
        </div>

        {/* Table */}
        <StudentTable label={""} size={10} api={APIConstant?.STUDENT_LIST} />
      </div>
    </div>
  );
};
