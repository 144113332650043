import { createSlice } from "@reduxjs/toolkit";

const initialClassState = {
    assignmentTitle: '',
    // classSpecficName: '',
    // students: [],
    // startDate: '',
    // endDate: ''
}

const assignmentSlice = createSlice({
    name: "assignment",
    initialState: initialClassState,
    reducers: {
        setAssignmentTitle(state, action) {
            state.assignmentTitle = action.payload;
        },
        // setClassSpecficName(state, action) {
        //     state.classSpecficName = action.payload;
        // },
        // setStudents(state, action) {
        //     state.students = action.payload;
        // },
        // setStartDates(state, action) {
        //     state.startDate = action.payload;
        // },
        // setEndDates(state, action) {
        //     state.endDate = action.payload;
        // }
    }
});

export default assignmentSlice;
export const { setAssignmentTitle, } = assignmentSlice.actions;
