// import React, { useState } from "react";
// import "./draganddrop.css"; // Add your own CSS styles
// import { images } from "../../constants/ImageImports";


// const DragAndDrop = ({ handleFileChange, width }) => {
//   const [image, setImage] = useState(null);

//   const onFileChange = (e) => {
//     const file = e.target.files[0];

//     if (file) {
//       const allowedTypes = [
//         "image/jpeg",
//         "image/png",
//         "application/pdf",
//         "application/msword",
//         "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
//         "text/plain"
//       ];

//       const maxSizeMB = 5;

//       // Check file type
//       if (!allowedTypes.includes(file.type)) {
//         alert("TOAST_MESSAGE.WRONG_FILE");
//         return;
//       }

//       // Check file size
//       const fileSizeMB = (file.size / (1024 * 1024)).toFixed(2);
//       if (fileSizeMB > maxSizeMB) {
//         alert("TOAST_MESSAGE.SIZE_ERROR");
//         return;
//       }

//       const reader = new FileReader();
//       reader.onload = (e) => {
//         setImage({ file: file, url: e.target.result });
//       };
//       reader.readAsDataURL(file);

//       handleFileChange([file]) // Pass selected file to parent component
//     }
//   };

//   return (
//     <div className="image-uploader">
//       <label style={{ width: width }} htmlFor="fileInput" className="custom-file-input">
//         <div className="image-upload">
//           {/* <div className="image-preview">
//             {image && (
//               <img
//                 style={{
//                   width: "250px",
//                   padding: "20px",
//                   height: "200px",
//                   objectFit: "cover",
//                 }}
//                 src={image.url}
//                 alt="Uploaded"
//               />
//             )}
//           </div> */}
//           <div className="d-flex">
//             <img src={images.upload_cloud} alt="upload_img" />
//             <div className="drop_supp">Drag & Drop or Choose</div>
//             <div className="drop_brow">  Questions & Answers File</div>
//             <div className="drop_supp">File!</div>
//             <div className="drop_file"> Doc, PDF or TXT</div>

//           </div>
//           <input
//             type="file"
//             id="fileInput"
//             onChange={onFileChange}
//             accept=".pdf,.doc,.docx,.txt"
//           // Remove multiple attribute for single file upload
//           />
//         </div>
//       </label>
//     </div>
//   );
// };

// export default DragAndDrop;


import React, { useState } from "react";
import "./draganddrop.css"; // Add your own CSS styles
import { images } from "../../constants/ImageImports";

const DragAndDrop = ({ handleFileChange, width }) => {
  const [image, setImage] = useState(null);
  const [fileName, setFileName] = useState(""); // State to store the file name

  const onFileChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      const allowedTypes = [
        "image/jpeg",
        "image/png",
        "application/pdf",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "text/plain"
      ];

      const maxSizeMB = 5;

      // Check file type
      if (!allowedTypes.includes(file.type)) {
        alert("TOAST_MESSAGE.WRONG_FILE");
        return;
      }

      // Check file size
      const fileSizeMB = (file.size / (1024 * 1024)).toFixed(2);
      if (fileSizeMB > maxSizeMB) {
        alert("TOAST_MESSAGE.SIZE_ERROR");
        return;
      }

      const reader = new FileReader();
      reader.onload = (e) => {
        setImage({ file: file, url: e.target.result });
        setFileName(file.name); // Set the file name in state
      };
      reader.readAsDataURL(file);

      handleFileChange([file]); // Pass selected file to parent component
    }
  };

  return (
    <div className="image-uploader">
      <label style={{ width: width }} htmlFor="fileInput" className="custom-file-input">
        <div className="image-upload">
          <div className="d-flex">
            <img src={images.upload_cloud} alt="upload_img" />
            <div className="drop_supp">Drag & Drop or Choose</div>
            <div className="drop_brow">Questions & Answers File</div>
            <div className="drop_supp">File!</div>
            <div className="drop_file">Doc, PDF or TXT</div>
          </div>
          <input
            type="file"
            id="fileInput"
            onChange={onFileChange}
            accept=".pdf,.doc,.docx,.txt"
          />
        </div>
      </label>
      {fileName && (
        <div className="file-name">
          Selected file: {fileName}
        </div>
      )}
    </div>
  );
};

export default DragAndDrop;
