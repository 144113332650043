import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { images } from "../../constants/ImageImports";
import { SelectBox } from "../../components/common/formInputs/SelectBox";
import { MODAL_TYPE } from "../../redux/constant/reduxConstant";
import { mdActions } from "../../redux/slices/modalSlice";
import Skeleton from "@mui/material/Skeleton";
import axios from "axios";
import ChatProp from "./ChatProp";
import PdfGenerator from "../../components/common/pdf/PdfGenerator";
import { toast } from "react-toastify";
import {
  pdfDataAction,
  assignmentDataAction,
  pdfStartAction,
  promptListAction,
  todoListAction,
} from "../../redux/slices/todoSlice";
import { Link, useNavigate } from "react-router-dom";
import DatePicker from "../../components/common/DatePicker";
import moment from "moment";
import { Formik, Form, Field, ErrorMessage, useFormik } from "formik";
import * as Yup from "yup";
import Loader from "../../components/common/loader";
import ProgressBar from "../../components/common/linearloader/ProgressBar";

import { BACKENDURL } from "../../services/apiConstant";



const videoPromptsUse = [

  {
    id: 1,
    key: "Grade Level",
    title: "",
    preText: "Create an assignment overview only in 1 or 2 lines on the topic of",
    centerText: "for",
    postText: "students based on",
    inputType: "select",
    value: "",
    row: "2",
    width: "calc(50% - 20px)",
  },

  {
    id: 2,
    key: "Number of Questions",
    title: "",
    preText: "Create an assignment overview only in 1 or 2 lines on the topic of",
    centerText: "for",
    postText: "students based on",
    inputType: "select",
    value: "",
    row: "2",
    width: "calc(50% - 20px)",
  },
  {
    id: 3,
    key: "Question Type",
    title: "",
    preText: "Create an assignment overview only in 1 or 2 lines on the topic of",
    centerText: "for",
    postText: "students based on",
    inputType: "select",
    value: "",
    row: "2",
    width: "calc(50% - 20px)",
  },

  {
    id: 4,
    key: "Video ID or URL:",
    title: "",
    preText: "Create an assignment overview only in 1 or 2 lines on the topic of",
    centerText: "for",
    postText: "students based on",
    inputType: "text",
    value: "",
    row: "2",
    width: "calc(50% - 20px)",
  },

];

// const gradeLevels = [

//   { value: 'pre-k', label: 'Pre-K' },
//   { value: 'Grade 1', label: 'Grade 1' },
//   { value: 'Grade 2', label: 'Grade 2' },
//   { value: 'Grade 3', label: 'Grade 3' },
//   { value: 'Grade 4', label: 'Grade 4' },
//   { value: 'Grade 5', label: 'Grade 5' },
//   { value: 'Grade 6', label: 'Grade 6' },
//   { value: 'Grade 7', label: 'Grade 7' },
//   { value: 'Grade 8', label: 'Grade 8' },
//   { value: 'Grade 9', label: 'Grade 9' },
//   { value: 'Grade 10', label: 'Grade 10' },
//   { value: 'Grade 11', label: 'Grade 11' },
//   { value: 'Grade 12', label: 'Grade 12' },
//   { value: 'university', label: 'University' },
// ];

const questionLevels = [
  { value: '3', label: '3' },
  { value: '5', label: '5' },
  { value: '7', label: '7' },
  { value: '10', label: '10' },
];

const questionType = [
  { value: 'Multiple Choice', label: 'Multiple Choice' },
  { value: 'Free Response', label: 'Free Response' },
  { value: 'True or False', label: 'True or False' },
];



export const CreateToDoVideo = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [videoUrl, setVideoUrl] = useState('');
  const [subtitles, setSubtitles] = useState('');
  const [chatgptResponse, setChatgptResponse] = useState([]);
  const [chatgptQuesRes, setChatgptQuesRes] = useState([]);
  const [error, setError] = useState('');
  const [videoPrompts, setVideoPrompts] = useState(videoPromptsUse);
  const [selectedValues, setSelectedValues] = useState({});
  const [loadingPrompt, setLoadingPrompt] = useState(null);
  // const [isloading, setIsloading] = useState(false);
  // const [content, setContent] = useState("")
  // const [showModal, setShowModal] = useState(false);
  // const [modalContent, setModalContent] = useState('');
  // const [fileId, setFileId] = useState('');
  const className = useSelector((state) => state.newTodo.classSpecficName);


  const validationSchema = Yup.object().shape({
    "Grade Level": Yup.string().required("Grade Level is required"),
    "Number of Questions": Yup.string().required("Number of Questions is required"),
    "Question Type": Yup.string().required("Question Type is required"),
    "Video ID or URL:": Yup.string().required("Video ID or URL is required"),
  });

  const onSubmit = async (value, { setErrors }) => {
    console.log("onsubmitvalue==>", value
    )
    try {
      const response = await axios.get(`${BACKENDURL}/api/generate-qa?url=${value?.["Video ID or URL:"]}`);

      if (response.status === 200) {
        setSelectedValues({ Number_of_Questions: value?.["Number of Questions"], Question_Type: value?.["Question Type"], Grade_Level: value?.["Grade Level"] })
        setSubtitles(response.data.questions);
      }

    } catch (error) {
      console.log("error", error.response.data.error)
      setErrors({ "Video ID or URL:": error.response.data.error });
    }
  }


  const initialValues = videoPromptsUse.reduce((acc, prompt) => {
    console.log("prompt.key", prompt.key)
    acc[prompt.key] = prompt.value;
    return acc;
  }, {});

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: onSubmit,
    validationSchema: validationSchema,
    enableReinitialize: true,
  });


  const { handleSubmit, errors, values, setFieldValue, touched, setTouched } =
    formik;

  console.log("initialValues", errors)
  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   try {

  //     const response = await axios.get(`${BACKENDURL}/api/generate-qa?url=${videoUrl}`);
  //     if (response.status === 200) {
  //       setSubtitles(response.data.questions);
  //     }
  //   } catch (error) {
  //     console.log("error", error.response)
  //     setError(error.response.data.error);
  //   }
  // };

  // const handleGrade = (event, index) => {
  //   const newValue = event.target.value;
  //   let keyName = videoPromptsUse[index].key;
  //   keyName = keyName.replace(/\s+/g, '_');
  //   setSelectedValues((prevValues) => ({
  //     ...prevValues,
  //     [keyName]: newValue,
  //   }));
  // };

  const sendMessage = async (prompt) => {
    const apiKey = "sk-proj-rYU4k2lxBTtQAy4pkBNYT3BlbkFJXpKylN2nuRhVNZkZVgdE";
    const modelName = "gpt-4o";
    const OpenAI = require("openai");

    const openai = new OpenAI({ apiKey: apiKey, dangerouslyAllowBrowser: true });
    try {
      const completion = await openai.chat.completions.create({
        messages: [{ role: "assistant", content: prompt }],
        model: modelName,
      });
      return completion.choices[0].message.content;
    } catch (error) {
      console.error("Error sending message:", error);
      return null;
    }
  };


  useEffect(() => {
    const fetchResponses = async () => {
      const responses = [];
      const len = subtitles.length
      setLoading(true);
      console.log("selectedValues", selectedValues, selectedValues, selectedValues)

      let prompt
      // if (selectedValues.Question_Type === "True or False") {
      //   prompt = `Generate ${selectedValues.Number_of_Questions} ${selectedValues.Question_Type} questions for ${selectedValues.Grade_Level} based on the following text. Provide two response one with each question followed by its specific answer in HTML bullet point format , ensuring it adheres strictly to the structure and content provided without adding any extra elements or template literals and other with same questions only. -`
      // } else {

      //   prompt = `Generate ${selectedValues.Number_of_Questions} ${selectedValues.Question_Type} questions for ${selectedValues.Grade_Level} based on the following text. Provide two response one with each question followed by its specific answer in HTML format , ensuring it adheres strictly to the structure and content provided without adding any extra elements or template literals and other with same questions only. -`
      // }
      if (selectedValues.Question_Type === "True or False") {
        prompt = `Generate ${selectedValues.Number_of_Questions} ${selectedValues.Question_Type} questions for ${selectedValues.Grade_Level} based on the following text. Provide each question followed by its specific answer in HTML bullet point format , ensuring it adheres strictly to the structure and content provided without adding any extra elements or template literals. -`
      } else {

        prompt = `Generate ${selectedValues.Number_of_Questions} ${selectedValues.Question_Type} questions for ${selectedValues.Grade_Level} based on the following text. Provide each question followed by its specific answer in HTML format , ensuring it adheres strictly to the structure and content provided without adding any extra elements or template literals. -`
      }


      const fullPrompt = prompt + "\n" + subtitles.join();
      let promptQuestion
      for (let i = 0; i < len; i++) {

        const response = await sendMessage(fullPrompt);
        const cleanedResponse = response
          .replace(/```html|```/g, '')
          .replace(/<\/?html>/g, '');
        setLoadingPrompt('topic');
        console.log("cleanedResponse", cleanedResponse)
        promptQuestion = `Remove Answer from this response only get question without any changes of HTML format and css` + cleanedResponse
        if (response) {
          responses.push({ topic: `topic`, response: cleanedResponse });
        }
      }

      const responsenew = await sendMessage(promptQuestion);
      const cleanedResponse = responsenew
        .replace(/```html|```/g, '')
        .replace(/<\/?html>/g, '');
      setLoadingPrompt('topic');
      setChatgptQuesRes([{ topic: `topic`, response: cleanedResponse }])

      setChatgptResponse(responses);
      setLoading(false);
    };

    if (subtitles.length > 0) {
      fetchResponses();
    }
  }, [subtitles]);


  useEffect(() => {
    if (chatgptResponse?.length > 0 || chatgptQuesRes?.length > 0) {
      navigate('/view-todo-video', { state: { arrayData: chatgptResponse, quesData: chatgptQuesRes } });
    }
  }, [chatgptResponse, chatgptQuesRes])

  return (
    <div className="create_todo_page sec-padding ">
      {/* {loading && <Loader />} */}

      {loading && <ProgressBar loading={loading} text={loadingPrompt} />}
      <div className="cstm_Ctnr">
        <div className="table_wpr p-30 ">
          <div className="create_todo_wpr">
            <button className="unstyled-button" onClick={() => navigate(-1)}  ><img src={images.Button} alt="button" /></button>
            <div className="cmn_hdr mt-10">
              <h3>
                <b>YouTube Video Questions.</b>
              </h3>
            </div>

            <form onSubmit={handleSubmit} >

              <div className="new_form">

                {videoPrompts?.map((prompt, index) => (
                  <div className="form-group" key={prompt.id} style={{ width: prompt.width }} >

                    {prompt.inputType === "text" && (
                      <>
                        <div className="input_box">
                          <label htmlFor={`class-select${index}`} >{prompt.key}</label>
                          <input
                            type="text"
                            className="form-control"
                            id={`exampleFormControlInput${index}`}
                            placeholder={prompt.title}
                            // onChange={(e) => setVideoUrl(e.target.value)}
                            onChange={(event) => {
                              // setVideoUrl(event.target.value);
                              setFieldValue(prompt.key, event.target.value);
                            }}
                          />
                        </div>
                        {touched[prompt.key] && errors[prompt.key] ? (
                          <div className="err_msg">{errors[prompt.key]}</div>
                        ) : null}
                      </>
                    )}
                    {(prompt.key === "Video ID or URL:" && error && <p style={{ color: 'red' }}>{error}</p>)}
                    {prompt.inputType === "select" && (
                      <>
                        <div className="input_box">
                          <label htmlFor={`class-select${index}`} >{prompt.key}</label>
                          <select
                            className="form-control"
                            id={`exampleFormControlSelect${index}`}
                            data-bs-toggle="collapse"
                            // onChange={(event) => handleGrade(event, index)}
                            onChange={(event) => {
                              // handleGrade(event);
                              setFieldValue(prompt.key, event.target.value);
                            }}
                          >
                            <option value="">Select an option</option>
                            {
                              (prompt.key === "Grade Level" &&
                                // gradeLevels.map((grade, idx) => (
                                <option value={className}>{className}</option>
                                // ))
                              ) ||


                              (prompt.key === "Number of Questions" && questionLevels.map((level, idx) => (
                                <option key={idx} value={level.value}>{level.label}</option>
                              ))) ||
                              (prompt.key === "Question Type" && questionType.map((type, idx) => (
                                <option key={idx} value={type.value}>{type.label}</option>
                              )))}
                          </select>
                        </div>
                        {touched[prompt.key] && errors[prompt.key] ? (
                          <div className="err_msg">{errors[prompt.key]}</div>
                        ) : null}
                      </>
                    )}
                  </div>
                ))}
              </div>

              <button
                type="submit"
                className="common-button"
              // onClick={() => navigate('/view-todo-video')}
              // onClick={handleSubmit}
              // disabled={disable}
              >
                Generate
              </button>
            </form>
          </div>
        </div>
      </div>
    </div >
  );
};

