import { createSlice } from "@reduxjs/toolkit";

const initialTempState = {
  isRefresh: false,
};

const tempSlice = createSlice({
  name: "temp",
  initialState: initialTempState,
  reducers: {
    tempRefresh(state, action) {
      state.isRefresh = action.payload;
    },
  },
});

export default tempSlice;
export const { tempRefresh } = tempSlice.actions;
export const tempActioms = tempSlice.actions;
