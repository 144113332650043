import React from "react";

const NoDataFound = ({ tableData, loader }) => {
  return (
    <>
      {tableData?.data?.length === 0 && !loader ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            paddingTop: "40px",
            fontWeight: 800,
            color: "#000",
          }}
        >
          No Data Found
        </div>
      ) : loader ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            paddingTop: "40px",
            fontWeight: 800,
            color: "#000",
          }}
        >
          Fetching Data...
        </div>
      ) : (
        ""
      )}
    </>
  );
};
export default NoDataFound;
