import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form } from "formik";
import { images } from "../../constants/ImageImports";
import { APIConstant } from "../../services/apiConstant";
import CommonFormInput from "../../components/common/formInputs/CommonFormInput ";
import { SelectBox } from "../../components/common/formInputs/SelectBox";
import usePost from "../../services/customHook/usePost";
import useApi from "../../services/customHook/useApi";
import profileApiSlice from "../../redux/slices/profileSlice";
import { toast } from "react-toastify";
import Loader from "../../components/common/loader";

const profileSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(3, "Username should be between (2-40) characters")
    .max(39, "Username should be between (2-40) characters")
    .matches(/^[A-Za-z\s]+$/, "Only alphabets and spaces are allowed")
    .required("Required*"),
  email: Yup.string()
    .email("Email should be valid")
    .required("Required*")
    .matches(
      /^[^\s@]+@[^\s@]+\.(com|ca|io|uk|aus|in)$/,
      "Invalid email format"
    ),
  address: Yup.string().max(150, "Address must not exceed 150 characters."),

  phone: Yup.string()
    .length(10, "Phone no. should be of 10 digits")
    .matches(/^[0-9]+$/, "Phone number must contain only digits"),
});

const pronouns = ["He/Him", "She/Her", "They/Them", "Other"];

export const Profile = () => {
  const [loading, setLoading] = useState(true);
  const { data } = useSelector((state) => state.profile);
  const dispatch = useDispatch();
  const postMutation = usePost();
  const [errorGender, setErrorGender] = useState(false);
  const postProfileMutation = useApi(profileApiSlice, APIConstant?.GET_PROFILE);
  const [edit, setEdit] = useState(false);
  const [image, setImage] = useState({
    blob: "",
    src: "",
  });

  useEffect(() => {
    if (data !== null && data !== undefined && data !== "") {
      setLoading(false);
    }
  }, [data]);

  const handlePostProfileData = async (
    values,
    { setSubmitting, resetForm }
  ) => {
    if (values?.phone?.charAt(0) === "0") {
      toast.error("Please enter valid phone number");
    } else if (
      values?.phone?.charAt(0) === "0" &&
      values?.phone?.charAt(9) === "0"
    ) {
      toast.error("Please enter valid phone number");
    } else if (
      values?.age !== "" &&
      values?.age !== null &&
      values?.age !== undefined &&
      (values?.age < 12 || values?.age > 150)
    ) {
      toast.error("Please enter age between 11 and 150");
    } else {
      let formdata = new FormData();
      formdata.append("email", values.email);
      formdata.append("name", values.firstName);
      formdata.append("phone", values.phone);
      formdata.append("profile_image", image?.src ? image?.src : "");
      formdata.append("gender", values?.gender);
      formdata.append("age", values.age);
      formdata.append("address", values?.address);
      try {
        const result = await postMutation.mutate({
          url: APIConstant?.PROFILE_UPDATE,
          payload: formdata,
          contentType: "multipart/form-data",
        });
        resetForm();
        setImage();
        setSubmitting(false);

        try {
          setTimeout(() => {
            postProfileMutation.mutateAsync({});
            setEdit(!edit);
          }, 2000);
          setSubmitting(false);
        } catch (error) {
          console.error(error);
          setSubmitting(false);
        }
      } catch (error) {
        console.error("Error making profile API call:", error);
        setLoading(false);
        setSubmitting(false);
      }
    }
  };

  const ProfileData = () => {
    return (
      <>
        {loading && <Loader />}
        <div className="prf_details prf_tab">
          <div className="prf_img">
            <img
              src={
                data?.payload?.data?.photo !== null &&
                data?.payload?.data?.photo !== undefined &&
                data?.payload?.data?.photo !== ""
                  ? data?.payload?.data?.photo
                  : images?.profile_cover
              }
              crossOrigin="anonymous"
              alt="img"
            />
          </div>
          <div className="prf_description">
            <h5>{data?.payload?.data?.name}</h5>
            <div className="prf_fields">
              <div className="prf_field_Item">
                <div className="prf_label">Phone</div>
                <div className="prf_text">
                  <span className="prf_Icon">
                    <img src={images?.profilePhoneIcon} alt="img" />
                  </span>
                  {data?.payload?.data?.phone
                    ? data?.payload?.data?.phone
                    : "N/A"}
                </div>
              </div>
              <div className="prf_field_Item">
                <div className="prf_label">Email</div>
                <div className="prf_text">
                  <span className="prf_Icon">
                    <img src={images?.profileMailIcon} alt="img" />
                  </span>
                  {data?.payload?.data?.email}
                </div>
              </div>
              <div className="prf_field_Item">
                <div className="prf_label">Pronouns</div>
                <div className="prf_text">
                  <span className="prf_Icon">
                    <img src={images?.infoIico} alt="img" />
                  </span>
                  {data?.payload?.data?.gender}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const allowedFormats = [
    "image/jpeg",
    "image/jpg",
    "image/png",
    "image/svg+xml",
  ];
  const maxImageSize = 2 * 1024 * 1024;

  const ProfileEditData = () => {
    const imageHandler = (val) => {
      const getImage = val.target.files[0];
      if (
        getImage &&
        allowedFormats.includes(getImage.type) &&
        getImage.size <= maxImageSize
      ) {
        const imgUrl = URL.createObjectURL(getImage);
        setImage(getImage);
        setImage({
          src: getImage,
          blob: imgUrl,
        });
      } else {
        if (!allowedFormats.includes(getImage.type)) {
          toast.error(
            "Please choose a valid image format (jpg, jpeg, png, svg)"
          );
        } else if (getImage.size > maxImageSize) {
          toast.error("Image size should not exceed 2MB");
        }
      }
    };

    return (
      <div className="prf_details edit_details">
        <div className="prf_upload">
          <img
            className="preview_img"
            src={
              image?.blob
                ? image?.blob
                : data?.payload?.data?.photo || images?.profile_cover
            }
            crossOrigin="anonymous"
            alt="img"
          />
          <img src={images?.camera} alt="img" />
          <input type="file" onChange={imageHandler} />
        </div>

        <Formik
          initialValues={{
            firstName: data?.payload?.data?.name || "",
            phone: data?.payload?.data?.phone || "",
            email: data?.payload?.data?.email || "",
            age: data?.payload?.data?.age || "",
            address: data?.payload?.data?.address || "",
            gender: data?.payload?.data?.gender || "",
          }}
          validationSchema={profileSchema}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            setSubmitting(true);
            setLoading(true);
            handlePostProfileData(values, { setSubmitting, resetForm });
          }}
        >
          {({ isSubmitting, setFieldValue, values }) => (
            <Form className="save_btn">
              <div className="prf_description">
                <div className="prf_fields edit_fields">
                  <div className="form_row item2">
                    <CommonFormInput
                      className="label"
                      label="Name *"
                      type="text"
                      name="firstName"
                      placeholder="Enter Name"
                    />

                    <CommonFormInput
                      label="Phone"
                      type="tel"
                      name="phone"
                      placeholder="Enter Phone Number"
                    />

                    <CommonFormInput
                      disabled
                      label="Email *"
                      type="email"
                      name="email"
                      placeholder="Enter Email"
                    />

                    <SelectBox
                      style={{ width: "100%" }}
                      className={"input_wpr"}
                      label={"Pronouns *"}
                      data={pronouns}
                      value={values?.gender}
                      setValue={(value) => {
                        setFieldValue("gender", value);
                      }}
                      error={errorGender}
                      setError={setErrorGender}
                      placeholder={"Select Pronoun"}
                    />

                    <CommonFormInput
                      label="Address"
                      type="textarea"
                      name="address"
                      placeholder="Enter address"
                    />

                    <CommonFormInput
                      label="Age"
                      type="number"
                      name="age"
                      placeholder="Enter age"
                      onChange={(event) => {
                        if (event?.target?.value === "") {
                          setFieldValue("age", null);
                        } else {
                          setFieldValue("age", event?.target?.value);
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
              <button className="pink_btn btn_reset" type="submit">
                {isSubmitting ? "...Saving" : "Save"}
              </button>
            </Form>
          )}
        </Formik>
      </div>
    );
  };

  return (
    <div className="tab_wpr">
      <div className="tab_hdg_wpr">
        <h5 className="tab_hdg">{edit ? "Edit Profile" : "Profile"}</h5>
        <div
          className={
            edit
              ? `btn_group d-flex gap-10 save_btn_spc`
              : `btn_group d-flex gap-10`
          }
        >
          {edit && (
            <button
              className="theme_btn btn_reset"
              onClick={() => setEdit(!edit)}
            >
              Back
            </button>
          )}

          {!edit && (
            <button
              className="pink_btn btn_reset"
              onClick={() => setEdit(!edit)}
            >
              Edit Profile
            </button>
          )}
        </div>
      </div>
      {edit ? <ProfileEditData /> : <ProfileData />}
    </div>
  );
};
