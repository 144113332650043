import * as Yup from "yup";

export const courseNameValidation = Yup.object().shape({
  coursename: Yup.string()
    .matches(
      /^(?=.*[a-zA-Z])(?=.*[0-9])[a-zA-Z0-9]+$/,
      "Course name should be alphanumeric with a maximum limit of 15 characters"
    )
    .max(15, "Value must be at most 15 characters")
    .required("Required*"),
});

export const firstNameValidation = Yup.object().shape({
  firstName: Yup.string()
    .required("Required*")
    .matches(
      /^[a-zA-Z][a-zA-Z\s]{0,14}$/,
      "This must be alphabetic with a maximum of 15 characters"
    ),
});
export const lastNameValidation = Yup.object().shape({
  lastName: Yup.string()
    .required("This field is required.")
    .matches(
      /^[a-zA-Z][a-zA-Z\s]{0,14}$/,
      "This must be alphabetic with a maximum of 15 characters"
    ),
});

export const usernameValidation = Yup.object().shape({
  username: Yup.string()
    .required("This field is required")
    .matches(
      /^[a-zA-Z][a-zA-Z\s]{0,14}$/,
      "This must be alphabetic with a maximum of 15 characters"
    ),
});
export const studentnameValidation = Yup.object().shape({
  studentname: Yup.string()
    // .required("This field is required")
    .matches(
      /^[a-zA-Z][a-zA-Z\s]{0,14}$/,
      "Student name Should be in alphabets with a maximum limit of 15 characters"
    ),
});

export const classnameValidation = Yup.object().shape({
  classname: Yup.string()
    .max(15, "Must be less than 15 characters")
    .required("Required*"),
});

export const emailPattern = /^[^\s@]+@[^\s@]+\.(com|ca|io|uk|aus|in)$/;

export const emailValidation = Yup.object().shape({
  email: Yup.string()
    .matches(emailPattern, "Invalid email address")
    .required("Required*"),
});

export const emailStudentValidation = Yup.object().shape({
  email: Yup.string().matches(emailPattern, "Invalid email address"),
});

export const changePasswordValidation = Yup.object().shape({
  currentPassword: Yup.string()
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/,
      "Password must be on atleast 8 characters, one uppercase letter, one lowercase, one no. and one special character*"
    )
    .required("Required*"),
  password: Yup.string()
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/,
      "Password must be on atleast 8 characters, one uppercase letter, one lowercase, one no. and one special character*"
    )
    .required("Required*"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Please confirm your password"),
});

export const addressValidation = Yup.object().shape({
  address: Yup.string().max(150, "Address must not exceed 150 characters."),
});

export const phoneNumberValidation = Yup.object().shape({
  phone: Yup.string().matches(
    /^[1-9][0-9]{9}$/,
    "Phone number must be 10 digits and should not start with 0"
  ),
});

export const ageValidation = Yup.object().shape({
  age: Yup.number()
    .min(5, "Must be at least 5 years old")
    .max(140, "Age cannot exceed 140")
    .integer("Please enter a valid age"),
});

export const teacherAgeValidation = Yup.object().shape({
  age: Yup.number()
    .min(18, "Must be at least 18 years old")
    .max(140, "Age cannot exceed 140")
    .integer("Please enter a valid age"),
});

export const genderValidation = Yup.object().shape({
  gender: Yup.string().required("Gender is required"),
});

export const authSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required")
    .matches(
      /^[^\s@]+@[^\s@]+\.(com|ca|io|uk|aus|in)$/,
      "Invalid email format"
    ),
  password: Yup.string().required("Password is required"),
});

export const resetPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required")
    .matches(
      /^[^\s@]+@[^\s@]+\.(com|ca|io|uk|aus|in)$/,
      "Invalid email format"
    ),
  password: Yup.string().required("Password is required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Password confirmation is required"),
});
