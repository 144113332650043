import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { images } from "../../constants/ImageImports";
import { SelectBox } from "../../components/common/formInputs/SelectBox";
import { MODAL_TYPE } from "../../redux/constant/reduxConstant";
import { mdActions } from "../../redux/slices/modalSlice";
import Skeleton from "@mui/material/Skeleton";
import axios from "axios";
import PdfGenerator from "../../components/common/pdf/PdfGenerator";
import { toast } from "react-toastify";
import {
  pdfDataAction,
  assignmentDataAction,
  pdfStartAction,
  promptListAction,
  todoListAction,
} from "../../redux/slices/todoSlice";
import { Link, useLocation, useNavigate } from "react-router-dom";
import DatePicker from "../../components/common/DatePicker";
import moment from "moment";
import { useGoogleLogin } from '@react-oauth/google';
import Loader from "../../components/common/loader";
import { BACKENDURL } from "../../services/apiConstant";
import { clientId } from "../../services/apiConstant";

export const ViewToDoLesson = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState('');
  const [fileId, setFileId] = useState('');
  const location = useLocation();

  const [loading, setLoading] = useState(false);
  const [content, setContent] = useState("")
  const [copySuccess, setCopySuccess] = useState('');
  const arrayData = location?.state || {};

  const [refresh, setRefresh] = useState(false)

  const onSuccess = async (response) => {
    console.log('Login Success:', response);
    const expiryTime = new Date().getTime() + (response.expires_in * 1000);
    localStorage.setItem('token_expiry_time', expiryTime);
    // Store the access token in localStorage
    localStorage.setItem('google_access_token', response.access_token);
    // Handle further actions here, if needed
    await setContentAfterAuth()
  };

  const onFailure = (error) => {
    console.log('Login Failed:', error);
  };

  // const login = useGoogleLogin({
  //   clientId,
  //   onSuccess,
  //   onFailure,
  //   isSignedIn: true, // Whether the user should be signed in by default
  //   accessType: 'offline', // Access type for token retrieval
  //   scope: 'https://www.googleapis.com/auth/drive',
  // });

  const login = useGoogleLogin({
    clientId,
    onSuccess,
    onFailure,
    isSignedIn: true, // Keeps the user signed in
    accessType: 'offline', // Ensures a refresh token is returned
    prompt: 'consent', // Forces the consent screen to show
    scope: 'https://www.googleapis.com/auth/drive', // Your required scopes
  });
  function isTokenExpired() {
    const expiryTime = localStorage.getItem('token_expiry_time');
    return new Date().getTime() > expiryTime;
  }

  const handleButtonClick = () => {

    if (isTokenExpired()) {
      // Token refresh logic as described above
      alert('Your session has expired. Please sign in again.');
      login();
    } else {
      setContentAfterAuth()
    }
  };


  // const handleCopyClick = async (e) => {
  //   e.preventDefault();

  //   // Function to recursively extract text from DOM nodes
  //   const extractText = (node) => {
  //     let text = '';
  //     if (node.nodeType === Node.TEXT_NODE) {
  //       text += node.textContent; // Preserve whitespace within text nodes
  //     } else if (node.nodeType === Node.ELEMENT_NODE) {
  //       // Skip tables and styled elements (like <style> or <script>)
  //       if (node.nodeName.toLowerCase() === 'table' || node.nodeName.toLowerCase() === 'style' || node.nodeName.toLowerCase() === 'script') {
  //         return ''; // Skip this element
  //       }

  //       // Recursively extract text from child nodes
  //       node.childNodes.forEach(child => {
  //         text += extractText(child);
  //       });
  //     }
  //     return text;
  //   };

  //   // Extracting textual content from each response
  //   const textToCopy = arrayData?.map(response => {
  //     const tempElement = document.createElement('div');
  //     tempElement.innerHTML = response.response;
  //     return extractText(tempElement); // Extract text content using the function
  //   }).join('\n');

  //   // Copying text to clipboard
  //   if (!navigator.clipboard) {
  //     fallbackCopyTextToClipboard(textToCopy);
  //   } else {
  //     try {
  //       await navigator.clipboard.writeText(textToCopy);
  //       setCopySuccess('Text copied to clipboard!');
  //     } catch (err) {
  //       setCopySuccess('Failed to copy text');
  //       console.error('Failed to copy text: ', err);
  //     }
  //   }
  // };

  // const fallbackCopyTextToClipboard = (text) => {
  //   const textArea = document.createElement('textarea');
  //   textArea.value = text;
  //   document.body.appendChild(textArea);
  //   textArea.select();

  //   try {
  //     document.execCommand('copy');
  //     setCopySuccess('Text copied to clipboard!');
  //   } catch (err) {
  //     setCopySuccess('Failed to copy text');
  //     console.error('Fallback: Unable to copy', err);
  //   }
  //   document.body.removeChild(textArea);
  // };



  const setContentAfterAuth = async () => {
    try {
      setLoading(true);
      const apiKey = "sk-proj-rYU4k2lxBTtQAy4pkBNYT3BlbkFJXpKylN2nuRhVNZkZVgdE";
      const modelName = "gpt-4o";
      const OpenAI = require("openai");
      let arr = []
      const openai = new OpenAI({ apiKey: apiKey, dangerouslyAllowBrowser: true });

      // let collectedRes = await gptData?.map(item => item?.response).join(' ');
      // console.log("collectedRes", arrayData)
      // for (let i = 0; i < arrayData.length; i++) {
      //   const completion = await openai.chat.completions.create({
      //     messages: [{ role: "assistant", content: "To get a only response in proper HTML boiler plate without removing any tag and style font size is 16px - " + arrayData[i]?.response }],
      //     model: modelName,
      //   });
      //   const cleanedResponse = completion.choices[0].message.content
      //     .replace(/```html|```/g, '')
      //     .replace(/<\/?html>/g, '');
      //   arr.push({ topic: arrayData[i]?.title, response: cleanedResponse });
      // }

      // setContent(arr)
      setRefresh(!refresh)
      setContent(arrayData)
      setLoading(false);
    } catch (error) {
      console.error('Error setting content:', error);
    } finally {
      // setIsloading(false);
      setModalContent('');
    }
  };



  useEffect(() => {
    const getResponse = async () => {
      // API call using fetch

      // const cleanedResponse = content
      //   .replace(/```html|```/g, '') // Remove ```html and ```
      //   .replace(/<\/?html>/g, ''); // Remove <html> and </html> tags
      const accessToken = localStorage.getItem('google_access_token');
      if (!accessToken) {
        console.error('Access token not found in cookies');
        return;
      }
      try {
        const response = await fetch(`${BACKENDURL}/generate-pdf`, {
          method: 'POST',

          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`,
          },
          body: JSON.stringify({
            htmlContent: content,
            docName: "assignment"
          }),
        });

        if (response) {
          const data1 = await response.json();

          if (data1.status === 200) {
            const webViewLink = data1.response.webViewLink;
            toast.success("Google Docs open Successfully.");
            window.open(webViewLink, '_blank');
          } else {
            toast.error(data1.error);
            console.error('No webViewLink found in data1');
          }

        }
        // setIsloading(false);
      } catch (error) {
        console.error('Error:', error);
      }
    }
    if (content.length > 0) {
      getResponse();
    }
  }, [content, refresh])


  const handleWord = async (e) => {
    e.preventDefault();
    try {
      // Make POST request to backend
      const response = await axios.post(`${BACKENDURL}/download/word`, {
        htmlContent: arrayData, // Replace with your HTML content data
        name: 'word', // Replace with the desired file name
      }, {
        responseType: 'blob', // Specify the response type as blob
        headers: {
          'Content-Type': 'application/json',
        },
      });

      // Extract filename from Content-Disposition header
      const contentDisposition = response.headers['content-disposition'];
      const fileNameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      const matches = fileNameRegex.exec(contentDisposition);
      let fileName = 'assignment.docx'; // Default filename if not found

      if (matches != null && matches[1]) {
        fileName = matches[1].replace(/['"]/g, '');
      }

      // Create a blob from the response data
      const blob = new Blob([response.data], { type: 'application/octet-stream' });

      // Create a download URL for the blob
      const downloadUrl = URL.createObjectURL(blob);

      // Create a link element and click it to initiate download
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.download = fileName; // Use the extracted filename
      link.click();

      // Clean up
      URL.revokeObjectURL(downloadUrl);

    } catch (error) {
      console.error('Error:', error);
    }
  };


  const handlePdf = async (e) => {
    e.preventDefault();
    try {
      // let combine = gptData?.map(item => item?.response).join(' ')
      // console.log("combine", combine)
      // Make POST request to backend
      const response = await axios.post(`${BACKENDURL}/download/generate-pdf`, {
        htmlContent: arrayData,
        docName: 'assignment',
      }, {
        // responseType: 'blob',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      console.log("responseword", response)

      const fileUrl = `${BACKENDURL}${response.data.path}`;

      // Create a link element
      const link = document.createElement('a');
      link.href = fileUrl;
      link.setAttribute('target', '_blank'); // Open in a new window/tab
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error:', error);
    }
  };


  const handleText = async (e) => {
    e.preventDefault();
    // const textToCopy = arrayData.map(response => {
    //   return `${new DOMParser().parseFromString(response.response, 'text/html').documentElement.textContent}`;
    // }).join('\n');
    // const textToCopy = arrayData.map(response => {
    //   return new DOMParser().parseFromString(response.response, 'text/html').body.textContent;
    // }).join('\n\n');
    try {
      // Make POST request to backend
      const response = await axios.post(`${BACKENDURL}/convert/txt`, {
        htmlData: arrayData,
        textName: 'assignment',
      }, {
        // responseType: 'blob',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      console.log("response", response)
      // Attempt to get filename from the URL
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const fileName = response.headers['Content-Disposition']?.split('filename=')[1] || 'text.txt';
      console.log('Content-Disposition:', response.headers['Content-Disposition']);
      // Create a link element and trigger the download
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName.trim());
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <div className="create_todo_page sec-padding ">
      {loading && <Loader />}
      <div className="cstm_Ctnr">

        <button className="unstyled-button" onClick={() => navigate(-1)}  ><img src={images.Button} alt="button" />
        </button>
        <div className="table_wpr p-30 ">
          <div className="create_todo_wpr">
            <h5 className="show_data">Overview:</h5>
            {arrayData?.length > 0 && arrayData?.map((response, index) => (
              <div key={index}>
                <h4> {response.topic}</h4>
                <div dangerouslySetInnerHTML={{ __html: response.response }}></div>
              </div>
            ))}

          </div>

        </div>
        <div className="extra_doc">
          <ul>

            <li style={{ marginBottom: "0px" }}>
              <button style={{ border: "none" }}
                onClick={handleButtonClick}
              >
                <img src={images.google_doc_icon} alt="google_doc_icon" />
                <p>Google Doc</p>
              </button>
            </li>
            <span className="line" ></span>
            <li style={{ marginBottom: "0px" }}>
              <button style={{ border: "none" }}
                onClick={handlePdf}
              >
                <img src={images.pdf_icon} alt="pdf_icon" />
                <p>PDF</p>
              </button>
            </li >
            <span className="line" ></span>
            <li style={{ marginBottom: "0px" }}>
              <button style={{ border: "none" }}
                onClick={handleWord}
              >
                <img src={images.google_doc_icon} alt="google_doc_icon" />
                <p>MS Word</p>
              </button>
            </li>

          </ul>
        </div>
        <button
          type="button"
          className="common-button"
          onClick={() => navigate('/generate-todo-lesson',)}
        // onClick={handleGenerate}
        // disabled={disable}
        >
          Generate
        </button>
      </div>
    </div >
  );
};

