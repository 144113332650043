import React from "react";
import { useDispatch } from "react-redux";
import { images } from "../../constants/ImageImports";
import { MODAL_TYPE } from "../../redux/constant/reduxConstant";
import { mdActions } from "../../redux/slices/modalSlice";
import { ClassTable } from "./ClassTable";

export const ClassManagmentList = () => {
  const dispatch = useDispatch();
  const addClassModalHandler = () => {
    dispatch(mdActions.showModal(MODAL_TYPE?.ADD_NEW_CLASS));
  };

  return (
    <div className="class_list_page sec-padding">
      <div className="cstm_Ctnr">
        <div className="cmn_hdr">
          <h3>
            <b>Classes List</b>
          </h3>
          <div className="btn_group">
            <button
              className="btn_reset pink_btn"
              onClick={addClassModalHandler}
            >
              <span className="me-10">
                <img src={images?.plus} alt="img" />
              </span>
              Create Classes
            </button>
          </div>
        </div>

        {/* Table */}
        <ClassTable label={""} size={10} />
      </div>
    </div>
  );
};
