import React from "react";
import Cookies from "js-cookie";
import { Navigate } from "react-router-dom";
import Maincontainer from "./Maincontainer";

const PrivateRoutes = () => {
  const auth = Cookies.get("teacher_access_token");

  return auth !== "" ? <Maincontainer /> : <Navigate to="/login" />;
};

export default PrivateRoutes;
