import React, { useEffect } from "react";
import { WithTodoListHoc } from "../hoc";

const CompletedTask = (props) => {
  useEffect(() => {
    props.setApiType("past");
  });
  return <></>;
};
export default WithTodoListHoc(CompletedTask);
