import { createSlice } from "@reduxjs/toolkit";

const initialModalState = {
    isModal: false,
    type: '',
}

const modalSlice = createSlice({
    name: "modal",
    initialState: initialModalState,
    reducers: {
        showModal(state, action) {
            state.isModal = true;
            state.type = action.payload;
        },
        hideModal(state) {
            state.isModal = false;
            state.type= '';
        }
    }
});

export default modalSlice;
export const mdActions = modalSlice.actions;     
