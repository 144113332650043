import React, { useState } from "react";
import logo from "../../components/assets/images/logo.png";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import { emailValidation } from "../../utils/validation";
import useAuth from "../../services/customHook/useAuth";
import { APIConstant } from "../../services/apiConstant";
import usePost from "../../services/customHook/usePost";

export const AuthRightComponentForgot = () => {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const { mutateAsync } = usePost();
  const { login, isLoading, isAuthenticated } = useAuth();
  const [isButtonDisabled, setButtonDisabled] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: emailValidation,
    onSubmit: async (values) => {
      await handleLogin(values);
    },
  });

  const handleLogin = async (credentials) => {
    if (isButtonDisabled) {
      return;
    }

    setButtonDisabled(true);
    setTimeout(() => {
      setButtonDisabled(false);
    }, 1000);

    // await mutateAsync(APIConstant?.FORGOT, credentials);
    try {
      const { payload } = await mutateAsync({
        url: APIConstant?.FORGOT,
        payload: credentials,
      });
      if (isAuthenticated) {
        navigate("/login");
      }
    } catch (error) {}
  };
  return (
    <div className="auth_rgt">
      <form onSubmit={formik.handleSubmit}>
        <div className="auth_ctnt">
          <img src={logo} alt="img" />
          <h4>Welcome to R.I.S.E. Academy </h4>
          <h6>Forgot Password</h6>
        </div>
        <div className="auth_frm">
          <div className="input_field_Item">
            <div className="input_label">Email</div>
            <div className="input_field">
              <input
                type="email"
                placeholder="Enter your email"
                id="email"
                {...formik.getFieldProps("email")}
              />
              {formik.touched.email && formik.errors.email ? (
                <div className="error_msg">{formik.errors.email}</div>
              ) : null}
            </div>
          </div>
        </div>

        <div className="lgn_btn_wpr">
          <button type="submit">
            {isLoading ? "Sending Email..." : "Submit"}
          </button>
          <Link to="/login">Back</Link>
        </div>
      </form>
    </div>
  );
};
