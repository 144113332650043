import React, { useState } from "react";
import usePost from "../../../services/customHook/usePost";

export const BlockButton = ({ status, setBlock, api, data, id }) => {
  const { mutateAsync } = usePost();
  const [isBlock, setIsBlock] = useState(status);
  const [isBlockLoading, setIsBlockLoading] = useState(false);

  const blockHandler = async () => {
    setIsBlockLoading(true);
    try {
      const { payload } = await mutateAsync({
        url: api,
        payload: {
          id: id ? id : data?.user?._id,
          isBlocked: !isBlock,
        },
      });
      setIsBlockLoading(false);
      setIsBlock((prev) => !prev);
      setBlock((prev) => !prev);
    } catch (error) {}
  };

  return (
    <div
      className={`block_btn ${isBlock ? "" : "unblock_btn"} ${
        isBlockLoading ? "pe-none" : ""
      }`}
      onClick={blockHandler}
    >
      {isBlockLoading ? "Processing.." : <>{isBlock ? "Block" : "Unblock"}</>}
    </div>
  );
};
