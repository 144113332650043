import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { images } from "../../constants/ImageImports";
import { mdActions } from "../../redux/slices/modalSlice";
import usePost from "../../services/customHook/usePost";

export const Delete = ({
  setClose,
  data,
  setApiRefersh,
  refresh,
  api,
  payloadData,
}) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const postMutation = usePost();

  const deleteHandler = async () => {
    if (api) {
      let senData;
      if (payloadData) {
        senData = {
          todo_id: data?.id,
        };
      } else {
        senData = {
          id: data?.id,
        };
      }
      try {
        setIsLoading(true);
        const result = await postMutation.mutate({
          url: api,
          payload: senData,
        });
        setTimeout(() => {
          if (data?.length === 1) {
            data?.setPageNo(data?.pageNo - 1);
          } else {
            data?.setPageNo(data?.pageNo);
          }
          setApiRefersh(!refresh);
          setIsLoading(false);
          setClose(false);
          dispatch(mdActions.hideModal());
        }, 1000);
      } catch (error) {
        setIsLoading(false);
        console.error("Error making profile API call:", error);
      }
    } else {
      setApiRefersh(!refresh);
      setIsLoading(false);
      setClose(false);
      dispatch(mdActions.hideModal());
    }
  };

  const closeModal = () => {
    dispatch(mdActions.hideModal());
    setClose(false);
  };

  return (
    <div className="mdl_inr logout_mdl img_overlay">
      <div className="cross_icon" onClick={closeModal}>
        <img src={images?.modelCross} alt="img" className="prft_img" />
      </div>
      <div className="mdl_ctnt">
        <h5>Are you sure you want to Delete?</h5>
        <div className="btn-group mt-30">
          <button className="btn_reset pink_btn" onClick={deleteHandler}>
            {isLoading ? "Deleting.." : "Delete"}
          </button>
          <button className="btn_reset blue_btn" onClick={closeModal}>
            Cancel{" "}
          </button>
        </div>
      </div>
    </div>
  );
};
