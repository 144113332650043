import React, { useEffect, useState } from "react";
import { NotificationItem } from "../../features/notification/NotificationItem";
import "./Notification.css";
import { useDispatch } from "react-redux";
import { APIConstant } from "../../services/apiConstant";
import usePost from "../../services/customHook/usePost";
import Loader from "../../components/common/loader";

export const NotificationPage = () => {
  const dispatch = useDispatch();
  const { mutateAsync } = usePost();
  const [loading, setLoading] = useState(false);

  const [data, setData] = useState([]);

  const getData = async () => {
    setLoading(true);
    try {
      const { payload } = await mutateAsync({
        url: APIConstant?.GET_NOTIFICATION,
        isToastDiasble: true,
      });

      const groupedByDate = Object.values(
        payload?.data?.reduce((acc, obj) => {
          if (obj.publishedDate) {
            const date = obj.publishedDate.split("T")[0];
            if (!acc[date]) {
              acc[date] = [];
            }
            acc[date].push(obj);
          }
          return acc;
        }, {})
      );

      setData(groupedByDate);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      {loading && data?.length === 0 && <Loader />}
      <div className="notification-page sec-padding">
        <div className="cstm_Ctnr">
          <div className="ntf_List">
            {data?.length === 0 && !loading ? (
              <b
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "80vh",
                }}
              >
                NO DATA FOUND
              </b>
            ) : (
              data?.map((item, i) => (
                <NotificationItem text={item} index={i} key={i} />
              ))
            )}
          </div>
        </div>
      </div>
    </>
  );
};
