import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../../components/common/table/table.css";
import { images } from "../../constants/ImageImports";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { Link } from "react-router-dom";
import { APIConstant } from "../../services/apiConstant";
import usePost from "../../services/customHook/usePost";
import moment from "moment";
import { EditClass } from "../../components/modals/EditClass";
import { Delete } from "../../components/modals/Delete";
import Loader from "../../components/common/loader";

export const ClassTable = ({
  label,
  size,
  isPaginationDisable,
  isSearchDisable,
}) => {
  const { mutateAsync } = usePost();
  const [classData, setClassData] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [classSingleData, setClassSingleData] = useState({
    name: "",
    id: "",
  });
  const [pageNo, setPageNo] = useState(1);
  const [isShow, setIsShow] = useState(false);
  const [isHit, setIsHit] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const isRefresh = useSelector((state) => state.temp.isRefresh);
  const [loading, setLoading] = useState(true);

  const handlePost = async (item) => {
    setLoading(true);
    try {
      const { payload } = await mutateAsync({
        url: APIConstant?.CLASS_LIST,
        payload: {
          page: pageNo,
          search: item,
          pageSize: size,
        },
        isToastDiasble: true,
      });
      setClassData(payload);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handlePost();
  }, [pageNo, isHit, isRefresh]);

  // Debounce function
  const debounce = (func, delay) => {
    let timeoutId;
    return function (...args) {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  const handleSearch = debounce((searchTerm) => {
    handlePost(searchTerm);
  }, 1200);

  const handleInputChange = (e) => {
    const { value } = e.target;
    setSearchTerm(value);
    handleSearch(value);
  };

  const ActionButtons = ({ itemData }) => {
    return (
      <ul className="action_btn_wpr">
        <li>
          <Link to={`/class/student-list/${itemData?._id}`}>
            <img
              className="prft_img circle transition"
              src={images?.tableView}
              alt="img"
            />
          </Link>
        </li>

        <li onClick={() => editClassModalHandler(itemData)}>
          <img
            className="prft_img circle transition"
            src={images?.tableEdit}
            alt="img"
          />
        </li>
        <li onClick={() => deleteHandler(itemData)}>
          <img
            className="prft_img circle transition"
            src={images?.tableDelete}
            alt="img"
          />
        </li>
      </ul>
    );
  };

  const pagingHandler = (event, value) => {
    setPageNo(value);
  };

  const CustomPagination = () => {
    return (
      <Stack spacing={2}>
        <Pagination
          count={classData?.data?.pagination?.totalPages}
          page={pageNo}
          shape="rounded"
          onChange={pagingHandler}
        />
      </Stack>
    );
  };

  const deleteHandler = (itemData) => {
    setClassSingleData({
      ...classSingleData,
      name: itemData?.class_title,
      id: itemData?._id,
      setPageNo: setPageNo,
      pageNo: pageNo,
      length: classData?.data?.result?.length,
    });
    setIsDelete(!isDelete);
  };

  const editClassModalHandler = (itemData) => {
    setIsShow(!isShow);
    setClassSingleData({
      ...classSingleData,
      name: itemData?.class_title,
      id: itemData?._id,
    });
  };

  return (
    <>
      {loading && <Loader />}
      <div className="table_wpr">
        {isShow && (
          <div className="cstm_mdl">
            <EditClass
              refresh={isHit}
              setApiRefersh={setIsHit}
              setClose={setIsShow}
              data={classSingleData}
            />
          </div>
        )}
        {isDelete && (
          <div className="cstm_mdl">
            <Delete
              data={classSingleData}
              refresh={isHit}
              setApiRefersh={setIsHit}
              setClose={setIsDelete}
              api={APIConstant?.CLASS_DELETE}
            />
          </div>
        )}
        <div className="table_hdr">
          <h4>{label}</h4>
          {!isSearchDisable && (
            <div className="search_wpr">
              <input
                type="text"
                placeholder="Search"
                onChange={handleInputChange}
                value={searchTerm}
              />
              <span>
                <img src={images?.searchIcon} alt="img" />
              </span>
            </div>
          )}
        </div>
        <div className="table_responsive">
          <table className="table">
            <thead>
              <tr>
                <th>S.No</th>
                <th style={{ width: "300px" }}>Class Name</th>
                <th className="text-center">Created Date</th>
                <th className="text-center">No. of student</th>
                <th className="text-center">Actions</th>
              </tr>
            </thead>

            {classData?.data?.result?.length > 0 && (
              <tbody>
                {classData?.data?.result?.map((item, i) => {
                  return (
                    <tr key={item?._id}>
                      <td>{i + 1}</td>
                      <td>
                        <p className="wbreak">{item?.class_title}</p>
                      </td>
                      <td className="text-center">
                        {moment(item?.publishedDate).format("DD-MM-YYYY")}
                      </td>
                      <td className="text-center">{item?.studentCount}</td>
                      <td>
                        <ActionButtons itemData={item} />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            )}
          </table>
          {classData?.data?.result?.length === 0 ? (
            <p className="empty">No Data Found</p>
          ) : (
            ""
          )}
        </div>
        {!isPaginationDisable && (size < 10 ? "" : <CustomPagination />)}
      </div>
    </>
  );
};
