import { ChangePassword } from "../features/setting/ChangePassword";
import { ContactUs } from "../features/setting/ContactUs";
import { Profile } from "../features/setting/Profile";
import {
  CompletedTask,
  ShowTab,
  UpcomingTask,
  PresentTask,
} from "../features/todoListTabContent";

import { images } from "./ImageImports";

export const SettingListTabbing = [
  { title: "Edit Profile", content: <Profile />, icon: images?.all },
  {
    title: "Change Password",
    content: <ChangePassword />,
    icon: images?.changePasswordTabIcon,
  },
  { title: "Contact Us", content: <ContactUs />, icon: images?.contactIcon },
];
