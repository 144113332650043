import React, { useEffect, useState, useMemo } from "react";
import "../../components/common/table/table.css";
import { useDispatch } from "react-redux";
import { images } from "../../constants/ImageImports";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { Link } from "react-router-dom";
import { APIConstant } from "../../services/apiConstant";
import usePost from "../../services/customHook/usePost";
import { EditClass } from "../../components/modals/EditClass";
import { Delete } from "../../components/modals/Delete";
import { BlockButton } from "../../components/common/table/BlockButton";
import { studentAction } from "../../redux/slices/studentSlice";
import Loader from "../../components/common/loader";

export const StudentTable = ({
  label,
  size,
  api,
  editDisable,
  viewLink,
  id,
  isPaginationDisable,
  isSearchDisable,
}) => {
  const { mutateAsync } = usePost();
  const dispatch = useDispatch();
  const [blockStatus, setBlockStatus] = useState("");
  const [studentData, setStudentData] = useState({});
  const [classSingleData, setClassSingleData] = useState({
    name: "",
    id: "",
    setPageNo: () => {},
    pageNo: "",
  });
  const [pageNo, setPageNo] = useState(1);
  const [isShow, setIsShow] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [isHit, setIsHit] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const memoizedStudentData = useMemo(() => studentData, [studentData]);
  const memoizedClassSingleData = useMemo(
    () => classSingleData,
    [classSingleData]
  );

  const handlePost = async (item) => {
    let payloadData;
    if (id) {
      payloadData = {
        id: id,
        page: pageNo,
        search: item,
        pageSize: size,
      };
    } else {
      payloadData = {
        page: pageNo,
        search: item,
        pageSize: size,
      };
    }
    setIsLoading(true);
    try {
      const { payload } = await mutateAsync({
        url: api,
        payload: payloadData,
        isToastDiasble: true,
      });
      setStudentData(payload);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    handlePost();
  }, [pageNo, isHit, blockStatus]);

  const ActionButtons = ({ itemData }) => {
    if (id) {
      return (
        <ul className="action_btn_wpr">
          <li>
            <Link
              to={
                viewLink
                  ? `${viewLink + "/" + itemData?._id}`
                  : `/student-detail/${itemData?._id}`
              }
              onClick={() =>
                dispatch(studentAction?.setStudentDetail(itemData))
              }
            >
              <img
                className="prft_img circle transition"
                src={images?.tableView}
                alt="img"
              />
            </Link>
          </li>

          {!editDisable && (
            <li>
              <Link
                to={`/student-edit/${itemData?._id}`}
                onClick={() =>
                  dispatch(studentAction?.setStudentDetail(itemData))
                }
              >
                <img
                  className="prft_img circle transition"
                  src={images?.tableEdit}
                  alt="img"
                />
              </Link>
            </li>
          )}
          <li onClick={() => deleteHandler(itemData)}>
            <img
              className="prft_img circle transition"
              src={images?.tableDelete}
              alt="img"
            />
          </li>
        </ul>
      );
    } else {
      return (
        <ul className="action_btn_wpr">
          <li>
            <Link
              to={
                viewLink
                  ? `${viewLink + "/" + itemData?.user?._id}`
                  : `/student-detail/${itemData?.user?._id}`
              }
              onClick={() =>
                dispatch(studentAction?.setStudentDetail(itemData))
              }
            >
              <img
                className="prft_img circle transition"
                src={images?.tableView}
                alt="img"
              />
            </Link>
          </li>

          {!editDisable && (
            <li>
              <Link
                to={`/student-edit/${itemData?.user?._id}`}
                onClick={() =>
                  dispatch(studentAction?.setStudentDetail(itemData))
                }
              >
                <img
                  className="prft_img circle transition"
                  src={images?.tableEdit}
                  alt="img"
                />
              </Link>
            </li>
          )}
          <li onClick={() => deleteHandler(itemData)}>
            <img
              className="prft_img circle transition"
              src={images?.tableDelete}
              alt="img"
            />
          </li>
        </ul>
      );
    }
  };

  const pagingHandler = (event, value) => {
    setPageNo(value);
  };

  const CustomPagination = () => {
    return (
      <Stack spacing={2}>
        <Pagination
          count={studentData?.data?.pagination?.totalPages}
          page={pageNo}
          shape="rounded"
          onChange={pagingHandler}
        />
      </Stack>
    );
  };

  const deleteHandler = (itemData) => {
    dispatch(studentAction?.setStudentDetail(itemData));

    setClassSingleData({
      ...classSingleData,
      name: itemData?.class_title,
      id: id ? itemData?._id : itemData?.user?._id,
      setPageNo: setPageNo,
      pageNo: pageNo,
      length: studentData?.data?.result?.length,
    });
    setIsDelete(!isDelete);
  };

  const debounce = (func, delay) => {
    let timeoutId;
    return function (...args) {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  const handleSearch = debounce((searchTerm) => {
    handlePost(searchTerm);
  }, 1200);

  const handleInputChange = (e) => {
    const { value } = e.target;
    setSearchTerm(value);
    handleSearch(value);
  };

  useEffect(() => {
    const allUsersNull = studentData?.data?.result?.every(
      (item) => item?.user === null
    );
  }, [studentData]);

  return (
    <>
      {isLoading && <Loader />}
      <div className="table_wpr">
        {isShow && (
          <div className="cstm_mdl">
            <EditClass
              refresh={isHit}
              setApiRefersh={setIsHit}
              setClose={setIsShow}
              data={memoizedClassSingleData}
            />
          </div>
        )}
        {isDelete && (
          <div className="cstm_mdl">
            <Delete
              data={memoizedClassSingleData}
              refresh={isHit}
              setApiRefersh={setIsHit}
              setClose={setIsDelete}
              api={APIConstant?.STUDENT_DELETE}
            />
          </div>
        )}
        <div className="table_hdr">
          <h4>{label}</h4>
          {!isSearchDisable && (
            <div className="search_wpr">
              <input
                type="text"
                placeholder="Search"
                onChange={handleInputChange}
                value={searchTerm}
              />
              <span>
                <img src={images?.searchIcon} alt="img" />
              </span>
            </div>
          )}
        </div>
        <div className="table_responsive">
          <table className="table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th className="text-center">Phone number</th>
                <th className="text-center">Pronouns</th>
                <th className="text-center">Age</th>
                <th className="text-center">Status</th>
                <th className="text-center">Actions</th>
              </tr>
            </thead>

            {memoizedStudentData?.data?.result?.length > 0 && (
              <tbody>
                {studentData?.data?.result?.map((item, i) => {
                  if (item?.user === null) {
                    return;
                  } else {
                    if (id) {
                      return (
                        <tr key={item?._id}>
                          <td>
                            <div className="pr_icn_wpr">
                              <span className="pr_icn">
                                <img
                                  className="circle prft_img"
                                  crossOrigin="*"
                                  src={
                                    item?.photo !== null &&
                                    item?.photo !== "" &&
                                    item?.photo !== undefined
                                      ? item?.photo
                                      : images?.profile_cover
                                  }
                                  alt="img"
                                />
                              </span>
                              {item?.name}
                            </div>
                          </td>
                          <td>{item?.email}</td>
                          <td className="text-center">
                            {item?.phone ? item?.phone : "N/A"}
                          </td>
                          <td className="text-center">
                            {item?.gender ? item?.gender : "N/A"}
                          </td>
                          <td className="text-center">
                            {item?.age !== undefined &&
                            item?.age !== null &&
                            item?.age !== "null" && item?.age !== ""
                              ? item?.age
                              : "N/A"}
                          </td>
                          <td className="df-h-center">
                            <BlockButton
                              id={id && item?._id}
                              data={item}
                              api={APIConstant?.STUDENT_STATUS}
                              status={item?.isBlocked}
                              setBlock={setBlockStatus}
                            />
                          </td>
                          <td className="text-center">
                            <ActionButtons itemData={item} />
                          </td>
                        </tr>
                      );
                    } else {
                      return (
                        <tr key={item?._id}>
                          <td>
                            <div className="pr_icn_wpr">
                              <span className="pr_icn">
                                <img
                                  className="circle prft_img"
                                  crossOrigin="*"
                                  src={
                                    item?.user?.photo !== null &&
                                    item?.user?.photo !== undefined &&
                                    item?.user?.photo !== ""
                                      ? item?.user?.photo
                                      : images?.profile_cover
                                  }
                                  alt="img"
                                />
                              </span>
                              {item?.user?.name}
                            </div>
                          </td>
                          <td>{item?.user?.email}</td>
                          <td className="text-center">
                            {item?.user?.phone ? item?.user?.phone : "N/A"}
                          </td>
                          <td className="text-center">
                            {item?.user?.gender ? item?.user?.gender : "N/A"}
                          </td>
                          <td className="text-center">
                            {item?.user?.age !== undefined &&
                            item?.user?.age !== null &&
                            item?.user?.age !== "null"
                              ? item?.user?.age
                              : "N/A"}
                          </td>
                          <td className="df-h-center">
                            <BlockButton
                              data={item}
                              api={APIConstant?.STUDENT_STATUS}
                              status={item?.user?.isBlocked}
                              setBlock={setBlockStatus}
                            />
                          </td>
                          <td className="text-center">
                            <ActionButtons itemData={item} />
                          </td>
                        </tr>
                      );
                    }
                  }
                })}
              </tbody>
            )}
          </table>
          {isLoading || memoizedStudentData?.data?.result?.length === 0 ? (
            <p className="empty">No Data Found</p>
          ) : (
            ""
          )}
        </div>
        {!isPaginationDisable &&
          (memoizedStudentData?.data?.pagination?.pageSize < 10 ? (
            ""
          ) : (
            <CustomPagination />
          ))}
      </div>
    </>
  );
};
