import React, { useEffect, useState } from "react";
import { images } from "../../constants/ImageImports";
import { MODAL_TYPE } from "../../redux/constant/reduxConstant";
import { mdActions } from "../../redux/slices/modalSlice";
import { MultipleSelect } from "../../components/common/formInputs/MultiSelect";
import usePost from "../../services/customHook/usePost";
import { APIConstant } from "../../services/apiConstant";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import axios from "axios";
import { toast } from "react-toastify";
import Skeleton from "@mui/material/Skeleton";
import { todoListAction } from "../../redux/slices/todoSlice";
import DatePicker from "../../components/common/DatePicker";
import {
  voiceRefreshAction,
  voiceToneAction,
  voiceTypeAction,
  voicelanguageAction,
} from "../../redux/slices/voiceModalSlice";
import { useNavigate } from "react-router-dom";
import { setClassName, setClassSpecficName, setEndDates, setStartDate, setStartDates, setStudents } from "../../redux/slices/newTodoSlice";


const NotFound = ({ data, selectedClass, loading }) => {
  return (
    <>
      {data?.length === 0 && selectedClass === "" && !loading ? (
        <small
          className="error_text"
          style={{ position: "relative", marginLeft: "13px" }}
        >
          No student found in this class
        </small>
      ) : (
        <small
          className="error_text"
          style={{ position: "relative", visibility: "hidden" }}
        >
          No student found in this class
        </small>
      )}{" "}
    </>
  );
};

const ACTIONS = [
  { icon: images?.tableEdit, key: "EDIT" },
  {
    icon: images?.tableDelete,
    key: "DELETE",
  },
];

export const CreateToDo = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { mutateAsync } = usePost();
  const [todoList, setTodoList] = useState(
    useSelector((state) => state.todo.todoList)
  );
  // const currentUserId = useSelector(
  //   (state) => state?.profile?.data?.payload?.data?._id
  // );
  // const isVoiceRefresh = useSelector((state) => state.voiceModal.isReferesh);
  // const AItone = useSelector((state) => state.voiceModal.isTone);
  // const AItype = useSelector((state) => state.voiceModal.type);
  // const AIlanguage = useSelector((state) => state.voiceModal.isLanguage);
  const [classList, setClassList] = useState([]);
  const [selectedClass, setSelectedClass] = useState("");
  const [selectedClassName, setSelectedClassName] = useState("");
  const [selecteStudent, setSelectedStudent] = useState([]);
  const [studentList, setStudentList] = useState([]);
  const [selectedDate, setSelectedDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [loading, setLoading] = useState(false);
  const [isEdit, setIsEdit] = useState("");

  // useEffect(() => {
  //   if (todoList?.length > 0) {
  //     setSelectedClass(todoList?.[0]?.class_id);
  //     setSelectedDate(todoList?.[0]?.task_start_date);
  //     setEndDate(todoList?.[0]?.task_end_date);
  //     setSelectedStudent(todoList?.[0]?.student_id);
  //   }
  // }, [todoList]);


  // const className = useSelector((state) => state.newTodo.className);
  // const students = useSelector((state) => state.newTodo.students);
  // const getStartDate = useSelector((state) => state.newTodo.startDate);
  // const getEndDate = useSelector((state) => state.newTodo.endDate);



  const updateClassInfo = (tool) => {
    // Dispatch the necessary actions
    dispatch(setClassName(selectedClass));
    dispatch(setStudents(selecteStudent));
    dispatch(setStartDates(selectedDate));
    dispatch(setEndDates(endDate));
    dispatch(setClassSpecficName(selectedClassName));
    // dispatch(setClassName(''))
    // dispatch(setStudents([]))
    // dispatch(setStartDates(''))
    // dispatch(setEndDates(''))
    // Check if the state has the necessary values set
    if (selectedClass && selecteStudent?.length && selectedDate && endDate) {
      navigate(tool.navigateTo);
    } else {
      alert("Please set class information first.")
      console.log("Please set class information first.");
      // Optionally, show an alert or some UI feedback
    }
  };


  const tools = [
    {
      header: "Assignment Plan Generator, the AI chatbot.",
      description: "The AI chatbot that crafts personalized study schedules to keep you on track.",
      image: images?.assignment_icon_1,
      alt: "assignment_icon_1",
      navigateTo: "/create-todo-assignment"
    },
    // {
    //   header: "Lesson Plan Generator, the AI chatbot.",
    //   description: "AI chatbot that creates customized lesson plans tailored to your curriculum and student needs.",
    //   image: images?.lesson_icon_2,
    //   alt: "lesson_icon_2",
    //   navigateTo: "/create-todo-lesson"
    // },
    {
      header: "YouTube Video Questions",
      description: "Generate guiding questions aligned to a YouTube video.",
      image: images?.youtube_icoon_3,
      alt: "youtube_icoon_3",
      navigateTo: "/create-todo-video"
    }
  ];

  const handleClassListPost = async () => {
    try {
      const { payload } = await mutateAsync({
        url: APIConstant?.STUDENT_CLASS_LIST,
        payload: {},
        isToastDiasble: true,
      });
      setClassList(payload?.data);
    } catch (error) { }
  };

  const handleStudetListPost = async () => {
    try {
      setLoading(true);
      const { payload } = await mutateAsync({
        url: APIConstant?.CLASS_STUDENT_LIST,
        payload: {
          id: selectedClass,
          page: 1,
          search: "",
          pageSize: 100,
        },
        isToastDiasble: true,
      });
      setStudentList(payload?.data?.result);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };


  // const formReset = () => {
  //   setSelectedClass("");
  //   setStudentList([]);
  //   setSelectedStudent([]);
  //   setSelectedDate("");
  //   setTitle("");
  //   setEndDate("");
  //   setDescription("");
  //   dispatch(voiceRefreshAction(false));
  //   dispatch(voiceToneAction(""));
  //   dispatch(voicelanguageAction("english"));
  //   dispatch(voiceTypeAction("short"));
  // };
  // const halfFormReset = () => {
  //   setTitle("");
  //   setDescription("");
  //   dispatch(voiceRefreshAction(false));
  //   dispatch(voiceToneAction(""));
  //   dispatch(voicelanguageAction("english"));
  //   dispatch(voiceTypeAction("short"));
  // };

  useEffect(() => {
    handleClassListPost();
  }, []);

  useEffect(() => {
    if (selectedClass !== "") {
      handleStudetListPost();
    }
  }, [selectedClass, isEdit]);

  const onClassChange = (event) => {
    console.log("classList", classList)
    console.log("event:209 " + event?.target?.value)

    let getClass = classList.find(x => x._id === event?.target?.value)
    setSelectedClassName(getClass?.class_title)
    setSelectedClass(event?.target?.value);
    setStudentList([]);
    setSelectedStudent([]);
  };

  const handleDateChange = (event) => {
    setSelectedDate(event?.target?.value);
    setEndDate(
      moment(event?.target?.value).add(6, "days").format("YYYY-MM-DD")
    );
  };


  return (
    <div className="create_todo_page sec-padding ">
      <div className="cstm_Ctnr">
        <div className="table_wpr p-50 ">
          <div className="cmn_hdr">
            <h3>
              <b>Create New To Do List</b>
            </h3>
          </div>
          <div className="create_todo_wpr mb-30">
            <div className="create_todo_lft create_form">
              <div className="todo_form item2">
                <div className="input_wpr custom-select-container">
                  <label htmlFor="class-select">Select a class:</label>
                  <select
                    className="select_inpt"
                    onChange={onClassChange}
                    value={selectedClass}
                  >
                    <option value="" disabled selected>
                      Select a class
                    </option>
                    {classList?.map((item, i) => {
                      return (
                        <option key={i} value={item?._id}>
                          {item?.class_title}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="input_wpr w-48">
                  <label htmlFor="class-select">Select Student:</label>
                  <div>
                    <MultipleSelect
                      placeholder={"Select Students"}
                      val={selecteStudent}
                      setAction={setSelectedStudent}
                      data={studentList}
                    />
                    <NotFound
                      loading={loading}
                      data={studentList}
                      selectedClass={selectedClass}
                    />
                  </div>
                </div>

                <DatePicker
                  id={"start-date"}
                  placeholder="Select Start Date"
                  handleDateChange={handleDateChange}
                  selectedDate={selectedDate}
                  isMinDate
                  minDate={moment().format("YYYY-MM-DD")}
                />
                <DatePicker
                  id={"end-date"}
                  placeholder="End Date"
                  disabled={true}
                  handleDateChange={() => { }}
                  selectedDate={endDate}
                  isMinDate
                  minDate={moment().format("YYYY-MM-DD")}
                />
              </div>
            </div>
          </div>
          <hr />
          <div className="tab_sec">
            <div className="cmn_hdr">
              <h2><b>Tools</b></h2>
            </div>
            <div className="sub_sec item2">
              {tools?.map((tool, index) => (
                <div
                  className="plan_gen"
                  // onClick={() => navigate(tool.navigateTo)}
                  onClick={() => updateClassInfo(tool)}
                  key={index}
                >
                  <img src={tool.image} alt={tool.alt} />
                  <div className="comm_text">
                    <div className="hdr_content">{tool.header}</div>
                    <p>
                      {tool.description.split("\n").map((line, i) => (
                        <React.Fragment key={i}>
                          {line}
                          <br />
                        </React.Fragment>
                      ))}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
