import Cookies from "js-cookie";

export const BACKENDURL = "https://dev.teacherapi.learnerlaunch.com"
// export const BACKENDURL = "http://192.168.1.12:8080"

export const clientId = '643253060678-l7s5mhev13po9nl2gj570dmisfl24qld.apps.googleusercontent.com';

export const unAuthorisedUser = [401, 404];
export const APIConstant = {
  MEDIA: "http://3.111.233.200:8080/",
  MEDIA_ADMIN: "http://3.111.233.200:8088/",
  ADMIN: "login",
  FORGOT: "forgot-password",
  CLASS_LIST: "view/class",
  CLASS_CREATE: "create/class",
  CLASS_UPDATE: "class/update",
  CLASS_DELETE: "class/delete",
  CLASS_STUDENT_LIST: "student/list/byClass",
  RESET_PASSWORD: "reset-password",

  STUDENT_LIST: "view/student",
  STUDENT_CLASS_LIST: "class/list",
  STUDENT_VIEW: "view/student/profile",
  STUDENT_UPDATE: "update/student/profile",
  CREATE_STUDENT: "create/student",
  STUDENT_DELETE: "student/delete",
  STUDENT_STATUS: "student/status/update",
  GET_PROFILE: "profile",
  PROFILE_UPDATE: "profile/update",
  CHANGE_PASSWORD: "change/password",
  CREATE_TODO: "create/todo",
  TODO_LIST: "get/todo",
  DELETE_TODO: "delete/todo",
  CONTACT_US: "get/contact-us",
  TODO_LIST_BY_CATEGORY: "todolist/byCategory",
  REVIEW_TASK: "review/task",
  STATIC: "get/static-content",
  UPDATE_ASSIGNMENT: "update/assignment",
  POST_CIOUD_TOKEN: "update/fmc",
  GET_NOTIFICATION: "notification/list",
  CHECK_LINK: "check-link",
  GET_LESSONTODOLIST: "lessontodolist/byClass"
};

export const isAuthenticated = () => {
  const token = Cookies.get("teacher_access_token");
  return token !== undefined && token !== null;
};
