import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { images } from "../../constants/ImageImports";
import { mdActions } from "../../redux/slices/modalSlice";
import {
  voiceRefreshAction,
  voicelanguageAction,
  voiceToneAction,
  voiceTypeAction,
} from "../../redux/slices/voiceModalSlice";

export const VoiceModal = () => {
  const dispatch = useDispatch();
  const isReferesh = useSelector((state) => state.voiceModal.isReferesh);
  const tone = useSelector((state) => state.voiceModal.isTone);
  const type = useSelector((state) => state.voiceModal.type);
  const language = useSelector((state) => state.voiceModal.isLanguage);

  const closeModal = () => {
    dispatch(mdActions.hideModal());
  };

  const updateHandler = () => {
    dispatch(mdActions.hideModal());
    dispatch(voiceRefreshAction(!isReferesh));
  };

  const toneHandler = (e) => {
    dispatch(voiceToneAction(e));
  };
  const languageHandler = (e) => {
    dispatch(voicelanguageAction(e));
  };
  const typeHandler = (e) => {
    dispatch(voiceTypeAction(e));
  };

  let toneList = [
    { id: "excited", label: "Excited", emoji: images?.emoji1 },
    { id: "positive", label: "Positive", emoji: images?.emoji2 },
    { id: "witty", label: "Witty", emoji: images?.emoji3 },
    { id: "serious", label: "Serious", emoji: images?.emoji4 },
    { id: "confident", label: "Confident", emoji: images?.emoji4 },
    { id: "straightforward", label: "Straightforward", emoji: images?.emoji4 },
    { id: "firm", label: "Firm", emoji: images?.emoji4 },
    { id: "encouraging", label: "Encouraging", emoji: images?.emoji4 },
  ];
  return (
    <div className="mdl_inr voice_mdl">
      <div className="cross_icon" onClick={closeModal}>
        <img src={images?.modelCross} alt="img" className="prft_img" />
      </div>
      <div className="mdl_hdr img_hdr left">
        <img src={images?.voiceIcon} alt="img" />
        <h4>My voice</h4>
      </div>
      <div className="mdl_ctnt">
        <h5 className="fz-20 pink-color left mb-20 mt-30">Tone of Voice</h5>
        <ul className="tone_list mb-30">
          {toneList.map((toneOption) => (
            <li key={toneOption.id}>
              <input
                type="radio"
                id={toneOption.id}
                name="tone"
                value={toneOption.id}
                onChange={(e) => toneHandler(e.target.value)}
                checked={tone === toneOption.id}
              />
              <label
                htmlFor={toneOption.id}
                className={tone === toneOption.id ? "selected" : ""}
              >
                <img src={toneOption.emoji} alt="img" />
                {toneOption.label}
              </label>
            </li>
          ))}
        </ul>
        <div className="input_wpr bg-select select__bx mb-20">
          <h5 className="fz-20 left mb-10">Language</h5>
          <select
            style={{ color: "black" }}
            onChange={(e) => languageHandler(e.target.value)}
            defaultValue={language}
          >
            <option disabled selected>
              Select Language
            </option>
            <option value={"english"}>English</option>
            <option value={"french"}>French </option>
            <option value={"spanish"}>Spanish</option>
          </select>
        </div>

        <div className="input_wpr bg-select select__bx ">
          <h5 className="fz-20 left mb-10 ">Description Type</h5>
          <select
            style={{ color: "black" }}
            onChange={(e) => typeHandler(e.target.value)}
            defaultValue={type}
          >
            <option disabled selected>
              Select Description Type
            </option>
            <option value={"short"}>Short</option>
            <option value={"medium"}>Medium </option>
            <option value={"long"}>Long</option>
          </select>
        </div>

        <div className="btn-group justify-end mt-30">
          <button className="btn_reset pink_btn" onClick={updateHandler}>
            Update
          </button>
          <button className="btn_reset blue_btn" onClick={closeModal}>
            Cancel{" "}
          </button>
        </div>
      </div>
    </div>
  );
};
