import React from "react";
import { images } from "../../../constants/ImageImports";

export const DownloadAssBtn = ({ item }) => {
  let url = item;
  return (
    <a download target="_blank" className="download_btn" style={{backgroundColor: "#197bbd"}} href={url}>
      <span>Student Assignment</span>
      <span>
        <img src={images?.downloadIcon} alt="image" />
      </span>
    </a>
  );
};
