import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Document,
  Page,
  Text,
  StyleSheet,
  Font,
  PDFViewer,
  PDFDownloadLink,
  View,
  Image,
} from "@react-pdf/renderer";
import usePost from "../../../services/customHook/usePost";
import { APIConstant } from "../../../services/apiConstant";
import Loader from "../loader";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import {
  assignmentDataAction,
  pdfDataAction,
  pdfStartAction,
  promptListAction,
  todoListAction,
} from "../../../redux/slices/todoSlice";
import * as htmlToImage from "html-to-image";
import { toPng, toJpeg, toBlob, toPixelData, toSvg } from "html-to-image";

const styles = StyleSheet.create({
  body: {
    width: "100%",
    height: "100%",
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  title: {
    fontSize: 15,
    marginBottom: 10,
  },
  author: {
    fontSize: 12,
    marginBottom: 20,
  },
  description: {
    fontSize: 12,
    lineHeight: "1.5em",
    marginBottom: 20,
  },
  subtitle: {
    fontSize: 18,
    lineHeight: "1.5em",
    margin: 12,
    marginTop: 15,
  },
  text: {
    margin: 12,
    fontSize: 14,
    textAlign: "justify",
  },
  image: {
    marginVertical: 20,
    marginHorizontal: "auto",
    maxWidth: "100%",
  },
  header: {
    fontSize: 18,
    marginBottom: 20,
    textAlign: "center",
    color: "#000",
    fontWeight: "600",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
  table: {
    display: "flex",
    width: "auto",
    borderStyle: "solid",
    borderColor: "#b2b2b2",
    borderWidth: 1,
    marginBottom: 10,
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
  },
  cell: {
    margin: "auto",
    padding: 5,
    fontSize: 12,
    textAlign: "left",
    borderBottom: "1px solid #b2b2b2",
  },
});

const PdfGenerator = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const postMutation = usePost();
  const pdfData = useSelector((state) => state.todo.pdfData);
  const assignmentData = useSelector((state) => state.todo.assignmentData);
  const todoListRedx = useSelector((state) => state.todo.todoList);
  const [loading, setLoading] = useState(false);
  const [table, setTable] = useState([]);
  const [imageURL, setImageURL] = useState(null);

  const sanitizeHtml = (input) => {
    const doc = new DOMParser().parseFromString(input, "text/html");
    return doc.body.textContent || "";
  };

  const extractTableData = (htmlString) => {
    const doc = new DOMParser().parseFromString(htmlString, "text/html");
    const table = doc.querySelector("table");
    const rows = table?.querySelectorAll("tr");
    const data = [];

    rows?.forEach((row) => {
      const cells = row.querySelectorAll("td, th");
      const rowData = [];

      cells.forEach((cell) => {
        rowData.push(cell.textContent.trim());
      });

      data.push(rowData);
    });
    return data;
  };

  useEffect(() => {
    const htmlString = props?.data[4].value;
    const extractedData = extractTableData(htmlString);
    setTable(extractedData);
  }, []);

  const MyDocument = () => (
    <Document>
      <Page size="A4" style={styles.body}>
        <Text style={styles.header}>{assignmentData?.title}</Text>

        {props?.data?.map((item, i) => (
          <React.Fragment key={i}>
            {i == 2 && (
              <>
                <Text style={styles.title}>Assessment Type:</Text>
                <Text style={styles.description}>
                  {assignmentData?.selectAssignment}
                </Text>
                <Text style={styles.title}>Product Type:</Text>
                <Text style={styles.description}>
                  {assignmentData?.selectProduct}
                </Text>
              </>
            )}
            {i !== 4 ? (
              <>
                {" "}
                <Text style={styles.title}>{sanitizeHtml(item?.key)}</Text>
                <Text style={styles.description}>
                  {sanitizeHtml(item?.value)}
                </Text>
              </>
            ) : (
              <>
                <Text style={styles.title}>{item?.key}</Text>
                <Image src={imageURL} />
              </>
            )}
          </React.Fragment>
        ))}

        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  );

  const formData = new FormData();
  const constructTodoListFormData = (todoList) => {
    if (Array.isArray(todoList)) {
      todoList.forEach((task, index) => {
        formData.append(`todo[${index}][class_id]`, task.class_id);

        if (Array.isArray(task.student_id)) {
          task.student_id.forEach((id, idIndex) => {
            formData.append(`todo[${index}][student_id][${idIndex}]`, id);
          });
        }
        formData.append(`todo[${index}][task_title]`, task.task_title);
        formData.append(`todo[${index}][task_content]`, task.task_content);
        formData.append(
          `todo[${index}][task_start_date]`,
          task.task_start_date
        );
        formData.append(`todo[${index}][task_end_date]`, task.task_end_date);
      });
    }

    return formData;
  };

  useEffect(() => {
    const formData = constructTodoListFormData(todoListRedx);
  }, [todoListRedx]);

  const sendPdfToApi = async (pdfBlob) => {
    setLoading(true);

    const studentIdArray = todoListRedx?.map((obj, i, arr) => {
      return {
        ...obj,
        student_id: obj?.student_id.map((obj1, ii, arrr) => obj1?._id),
      };
    });
    const formData = constructTodoListFormData(studentIdArray);
    formData.append("todoFile", pdfBlob, "document.pdf");
    formData.append("start_date", props?.assStartDate);
    formData.append("end_date", props?.assEndDate);
    formData.append("assessment_title", assignmentData?.title);
    formData.append("assessment_description", assignmentData?.textareaValue);
    formData.append("assessment_type", assignmentData?.selectAssignment);
    formData.append("product_type", assignmentData?.selectProduct);

    try {
      const result = await postMutation.mutate({
        url: APIConstant?.CREATE_TODO,
        payload: formData,
        contentType: "multipart/form-data",
      });

      setTimeout(() => {
        setLoading(false);
        dispatch(pdfStartAction(false));
        dispatch(promptListAction([]));
        dispatch(pdfDataAction([]));
        dispatch(todoListAction([]));
        dispatch(assignmentDataAction([]));
        navigate("/create-todo");
      }, 1500);
    } catch (error) {
      console.error("Error sending PDF to the API", error);
      setTimeout(() => {
        setLoading(false);
      }, 1500);
    }
  };
  const tableRef = useRef(null);
  useEffect(() => {
    const downloadImage = async () => {
      setLoading(true);
      try {
        if (tableRef.current) {
          const dataUrl = await htmlToImage.toPng(tableRef.current);
          setImageURL(dataUrl);
          setLoading(false);
        }
      } catch (error) {
        console.error("Error creating table image", error);
      }
    };

    downloadImage();
  }, []);

  return (
    <div>
      {loading && <Loader />}
      {!imageURL &&
        props?.data?.map((item, i) => {
          if (i === 4) {
            return (
              <div
                ref={tableRef}
                dangerouslySetInnerHTML={{ __html: item?.value }}
              ></div>
            );
          }
        })}

      <PDFViewer>
        <MyDocument />
      </PDFViewer>

      <div className="crt_btn">
        <PDFDownloadLink document={<MyDocument />} className="dwn-pdf">
          {({ blob, url, loading, error }) => (
            <div className="btn-group">
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  sendPdfToApi(blob);
                }}
                className="btn_reset pink_btn"
              >
                {loading
                  ? "Loading document..."
                  : "Confirm to Create Assignment"}
              </a>
            </div>
          )}
        </PDFDownloadLink>
        <Link to={"/create-todo"} class="btn_reset pink_btn">
          Back
        </Link>
      </div>
    </div>
  );
};

export default PdfGenerator;
