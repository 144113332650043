import React, { useState } from "react";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import CommonFormInput from "../../components/common/formInputs/CommonFormInput ";
import { SelectBox } from "../../components/common/formInputs/SelectBox";
import { ImageUpload } from "../../components/common/formInputs/ImageUpload";
import usePost from "../../services/customHook/usePost";
import { APIConstant } from "../../services/apiConstant";
import { useEffect } from "react";
import { images } from "../../constants/ImageImports";
import { ClassSelectBox } from "../../components/common/formInputs/ClassSelectBox";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/common/loader";

const pronouns = ["He/Him", "She/Her", "They/Them", "Other"];

const studentSchema = Yup.object().shape({
  studentname: Yup.string()
    .min(3, "Username should be between (2-40) characters")
    .max(39, "Username should be between (2-40) characters")
    .matches(/^[A-Za-z\s]+$/, "Only alphabets and spaces are allowed")
    .required("Required*"),
  email: Yup.string()
    .email("Email should be valid")
    .required("Required*")
    .matches(
      /^[^\s@]+@[^\s@]+\.(com|ca|io|uk|aus|in)$/,
      "Invalid email format"
    ),
  address: Yup.string().max(150, "Address must not exceed 150 characters."),
  coursename: Yup.string()
    .required("Required*")
    .max(30, "Course name must not exceed 30 characters"),

  phone: Yup.string()
    .length(10, "Phone no. should be of 10 digits")
    .matches(/^[0-9]+$/, "Phone number must contain only digits"),
});

export const StudentAdd = () => {
  const { mutateAsync } = usePost();
  const navigate = useNavigate();
  const [className, setClassName] = useState("");
  const [classId, setClassId] = useState("");
  const [errorClassName, setErrorClassName] = useState(false);
  const [classList, setClassList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [gender, setGender] = useState("");
  const [errorGender, setErrorGender] = useState(false);
  const [errorProfileBg, setErrorProfileBg] = useState(false);
  const [profilePic, setProfilePic] = useState({
    blob: images?.profile_cover,
    src: "",
  });
  const [errorProfilePic, setErrorProfilePic] = useState(false);
  const [profileBg, setProfileBg] = useState({
    blob: images?.bgCover,
    src: "",
  });

  const handleSubmit = async (
    values,
    { resetForm, setFieldError, setSubmitting }
  ) => {
    if (classId === "") {
      setErrorClassName("className", "Required*");
    } else if (gender === "") {
      setErrorGender("gender", "Required*");
    } else if (values?.phone?.charAt(0) === "0") {
      toast.error("Please enter valid phone number");
    } else if (
      values?.phone?.charAt(0) === "0" &&
      values?.phone?.charAt(9) === "0"
    ) {
      toast.error("Please enter valid phone number");
    } else if (
      values?.age !== "" &&
      values?.age !== null &&
      values?.age !== undefined &&
      (values?.age < 11 || values?.age > 99)
    ) {
      toast.error("Please enter age between 10 and 100");
    } else {
      setSubmitting(true);
      setIsLoading(true);
      let formdata = new FormData();
      formdata.append("name", values.studentname);
      formdata.append("email", values.email);
      formdata.append("phone", values.phone);
      formdata.append("gender", gender);
      formdata.append("age", values?.age);
      formdata.append("address", values?.address);
      formdata.append("profile_image", profilePic?.src);
      formdata.append("course_name", values?.coursename);
      formdata.append("class_id", classId);
      formdata.append("background", profileBg?.src);
      try {
        const result = await mutateAsync({
          url: APIConstant?.CREATE_STUDENT,
          payload: formdata,
          contentType: "multipart/form-data",
        });
        setIsLoading(false);
        setSubmitting(false);
        if (result?.name === "AxiosError") {
          setIsLoading(false);
          toast.error(result?.response?.data?.data);
          setIsLoading(false);
          setSubmitting(false);
        } else {
          resetForm();
          // toast.success("Student added successfully");
          navigate("/student-list");
          setIsLoading(false);
          setSubmitting(false);
        }
      } catch (error) {
        setIsLoading(false);
        setSubmitting(false);
        console.error("Error making Student API call:", error);
      }
    }
  };

  const handleClassListPost = async () => {
    try {
      const { payload } = await mutateAsync({
        url: APIConstant?.STUDENT_CLASS_LIST,
        payload: {},
        isToastDiasble: true,
      });
      setClassList(payload?.data);
    } catch (error) {}
  };

  useEffect(() => {
    handleClassListPost();
  }, []);

  useEffect(() => {
    classList.filter((item) => {
      if (item.class_title === className) {
        setClassId(item._id);
      }
    });
  }, [className]);

  return (
    <>
      {isLoading && <Loader />}
      <div className="student_list_page sec-padding ">
        <div className="cstm_Ctnr">
          <div className="tab_wpr">
            <Formik
              initialValues={{
                studentname: "",
                email: "",
                coursename: "",
                age: null,
                address: "",
                phone: "",
              }}
              validationSchema={studentSchema}
              onSubmit={handleSubmit}
            >
              {({ isSubmitting, errors, values }) => (
                <Form>
                  <ImageUpload
                    image={profileBg}
                    setImage={setProfileBg}
                    setImgErr={setErrorProfileBg}
                    imgError={errorProfileBg}
                    className="bg-upload"
                  />
                  <div className="d-flex flex-wrap edit_details mb-30">
                    <ImageUpload
                      image={profilePic}
                      setImage={setProfilePic}
                      setImgErr={setErrorProfilePic}
                      imgError={errorProfilePic}
                    />
                    <div className="prf_description d-flex justify-end align-center">
                      <div className="btn_group">
                        <button
                          type="submit"
                          disabled={isSubmitting}
                          className="btn_reset pink_btn "
                        >
                          {isLoading ? "Submitting.." : "Save"}
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="form_row item2">
                    <CommonFormInput
                      label="Student Name *"
                      type="text"
                      name="studentname"
                      placeholder="Enter name"
                    />
                    <CommonFormInput
                      label="Email *"
                      type="email"
                      name="email"
                      placeholder="Enter email"
                    />
                    <ClassSelectBox
                      className={"input_wpr"}
                      data={classList}
                      label={"Class Name *"}
                      value={className}
                      error={errorClassName}
                      setError={setErrorClassName}
                      setValue={setClassName}
                      placeholder={"Select Class"}
                    />
                    <CommonFormInput
                      label="Course name *"
                      type="text"
                      name="coursename"
                      placeholder="Enter Course name"
                    />
                    <SelectBox
                      className={"input_wpr"}
                      label={"Pronouns *"}
                      data={pronouns}
                      value={gender}
                      setValue={setGender}
                      error={errorGender}
                      setError={setErrorGender}
                      placeholder={"Select Pronoun"}
                    />
                    <CommonFormInput
                      label="Phone number"
                      type="tel"
                      name="phone"
                      placeholder="Enter phone number"
                    />
                    <CommonFormInput
                      label="Address"
                      type="textarea"
                      name="address"
                      placeholder="Enter address"
                    />
                    <CommonFormInput
                      label="Age"
                      type="number"
                      name="age"
                      placeholder="Enter age"
                    />
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
};
