
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { images } from "../../constants/ImageImports";
import { SelectBox } from "../../components/common/formInputs/SelectBox";
import { MODAL_TYPE } from "../../redux/constant/reduxConstant";
import { mdActions } from "../../redux/slices/modalSlice";
import Skeleton from "@mui/material/Skeleton";
import axios from "axios";
import ChatProp from "./ChatProp";
import PdfGenerator from "../../components/common/pdf/PdfGenerator";
import { toast } from "react-toastify";
import {
  pdfDataAction,
  assignmentDataAction,
  pdfStartAction,
  promptListAction,
  todoListAction,
} from "../../redux/slices/todoSlice";
import { Link, useNavigate } from "react-router-dom";
import DatePicker from "../../components/common/DatePicker";
import moment from "moment";
import { Formik, Form, Field, ErrorMessage, useFormik } from "formik";
import Typewriter from 'typewriter-effect';
import parse from 'html-react-parser';
import * as Yup from "yup";
import Loader from "../../components/common/loader";
import ProgressBar from "../../components/common/linearloader/ProgressBar";
// import assignmentSlice from "../../redux/slices/assignmentSlice";
import { setAssignmentTitle } from '../../redux/slices/assignmentSlice';



const predefinedPromptsUse = [
  {
    id: 1,
    key: "Topic",
    title: "Topic",
    preText: "Suppose you are creating a worksheet on the topic of",
    centerText: "for",
    postText: "students.",
    inputType: "text",
    value: "",
    row: "2",
    width: "calc(50% - 20px)",
  },
  {
    id: 2,
    key: "Grade Level",
    title: "",
    preText:
      "Create an assignment overview only in 1 or 2 lines on the topic of",
    centerText: "for",
    postText: "students based on",
    inputType: "select",
    value: "",
    row: "2",
    width: "calc(50% - 20px)",
  },

  {
    id: 3,
    key: "Success Criteria",
    title: "Success Criteria",
    preText:
      "List the 3 overall success criteria only in ul list in HTML format for a lesson on",
    centerText: "",
    postText:
      "students. Ensure these criteria are specific, measurable, and student-friendly.",
    inputType: "textarea",
    value: "",
    row: "3",
    width: "100%",
  },
  {
    id: 4,
    key: "Learning Outcomes",
    title: "Learning Outcomes",
    preText:
      "Define the 3 overall learning outcomes only in ul list in HTML format for a lesson on",
    centerText: "aimed at",
    postText:
      "students. What should students be able to know, understand, and do by the end of the lesson? ",
    inputType: "textarea",
    value: "",
    row: "3",
    width: "100%",
  },
  {
    id: 5,
    key: "Instruction",
    title: `Instruction`,
    preText: "Provide detailed instructions for students for an assignment on",
    centerText: "for",
    postText:
      "students. Include steps for introduction, main activities, and conclusion. Provide the response in HTML bullet point format.",
    inputType: "textarea",
    value: "",
    row: "3",
    width: "100%",
  },
  {
    id: 6,
    key: "Rubric",
    title: `A rubric for assessment`,
    preText: "Create an HTML table presenting a rubric focused on the topic of",
    centerText:
      "Use the following categories as vertical criteria: Knowledge/Understanding, Thinking/Inquiry, Communication, and Application. Arrange levels 1 through 4 horizontally.",
    postText:
      "Each cell in the table should have a thick black border to clearly divide the rows and columns in a tabular format.",
    inputType: "textarea",
    value: "",
    row: "3",
    width: "100%",
  },

  {
    id: 7,
    key: "Curriculum Expectations",
    title: `Curriculum Expectations`,
    preText: "Which specific detailed curriculum expectations are covered in",
    centerText: "from the",
    postText:
      "in the Ontario Social Science curriculum. Include the expectation number identifying the strand and the corresponding overall expectation. Provide the response in HTML bullet point format.",
    inputType: "textarea",
    value: "",
    row: "3",
    width: "100%",
  },
  {
    id: 8,
    key: "Standards Set to Align to:",
    title: `Any Standard World Wide`,
    preText: "",
    centerText: "",
    postText: "",
    inputType: "textarea",
    value: "",
    row: "1",
    width: "100%",
  },
];

// const gradeLevels = [
//   { value: "pre-k", label: "Pre-K" },
//   { value: "Grade 1", label: "Grade 1" },
//   { value: "Grade 2", label: "Grade 2" },
//   { value: "Grade 3", label: "Grade 3" },
//   { value: "Grade 4", label: "Grade 4" },
//   { value: "Grade 5", label: "Grade 5" },
//   { value: "Grade 6", label: "Grade 6" },
//   { value: "Grade 7", label: "Grade 7" },
//   { value: "Grade 8", label: "Grade 8" },
//   { value: "Grade 9", label: "Grade 9" },
//   { value: "Grade 10", label: "Grade 10" },
//   { value: "Grade 11", label: "Grade 11" },
//   { value: "Grade 12", label: "Grade 12" },
//   { value: "university", label: "University" },
// ];



export const CreateToDoAssignment = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const className = useSelector((state) => state.newTodo.classSpecficName);
  const [grade, setGrade] = useState(className);
  const [predefinedPrompts, setPredefinedPrompts] =
    useState(predefinedPromptsUse);
  const [gptData, setGptData] = useState([]);
  const [disable, setDisable] = useState(true);
  const [show, setShow] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingPrompt, setLoadingPrompt] = useState(null);
  // const students = useSelector((state) => state.newTodo.students);
  // const getStartDate = useSelector((state) => state.newTodo.startDate);
  // const getEndDate = useSelector((state) => state.newTodo.endDate);

  const handleChange = (event, index) => {
    const updatedPrompts = predefinedPrompts.map((prompt, i) => {
      if (i === index) {
        return {
          ...prompt,
          value: event.target.value,
        };
      }
      return prompt;
    });

    if (index === 7) {
      setShow(event.target.value);
    }
    setPredefinedPrompts(updatedPrompts);
  };

  const sendMessage = async (prompt) => {
    const apiKey = "sk-proj-rYU4k2lxBTtQAy4pkBNYT3BlbkFJXpKylN2nuRhVNZkZVgdE";
    const modelName = "gpt-4o";
    const OpenAI = require("openai");

    const openai = new OpenAI({
      apiKey: apiKey,
      dangerouslyAllowBrowser: true,
    });
    try {
      const completion = await openai.chat.completions.create({
        messages: [{ role: "system", content: prompt }],
        model: modelName,
      });


      return completion.choices[0].message.content;
    } catch (error) {
      console.error("Error sending message:", error);
      return null;
    }
  };


  useEffect(() => {
    if (data == null) {
      setPredefinedPrompts([...predefinedPromptsUse])
      return
    }

    setDisable(false);

    const updatedPrompts = predefinedPrompts.map((prompt) => {
      switch (prompt.id) {
        case 3:
        case 4:
          return {
            ...prompt,
            value: `${prompt.preText} ${prompt.centerText} ${data}`,
          };
        case 5:
          return {
            ...prompt,
            value: `${prompt.preText} ${data} ${prompt.centerText} ${grade} ${prompt.postText}`,
          };
        case 2:
          return {
            ...prompt,
            value: `${prompt.preText} ${data} ${prompt.centerText} ${grade} ${prompt.postText} ${show}`,
          };
        case 6:
          return {
            ...prompt,
            value: `${prompt.preText} ${data} ${prompt.centerText} ${prompt.postText}`,
          };
        case 7:
          return {
            ...prompt,
            value: `${prompt.preText} ${data} ${prompt.centerText
              } ${grade} ${"course code"} ${show} ${prompt.postText}`,
          };
        default:
          return prompt;
      }
    });

    setPredefinedPrompts(updatedPrompts);
  }, [data, grade, show]);

  const validationSchema = Yup.object().shape({
    "Grade Level": Yup.string().required("Grade Level is required"),
    "Success Criteria": Yup.string().required("Success Criteria is required"),
    "Learning Outcomes": Yup.string().required("Learning Outcomes is required"),
    Instruction: Yup.string().required("Instruction is required"),
    Rubric: Yup.string().required("Rubric is required"),
    "Curriculum Expectations": Yup.string().required(
      "Curriculum Expectations is required"
    ),

  });

  const onSubmit = async (value) => {
    console.log("onsubmitvalue==>", value)
    setLoading(true);

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });

    const responses = [];

    // Iterate over keys of the object
    for (const key of Object.keys(value)) {
      const prompt = {
        id: (key),
        title: key,
        value: value[key],
      };

      let fontSize = "20px"; // Default font size
      if (prompt.id === 6) {
        fontSize = "18px";
      } else if (prompt.id === 7) {
        fontSize = "22px";
      }

      // Construct the message to send
      const message = `${prompt.value} in proper spacing and font size is ${fontSize}`;
      const response = await sendMessage(message);
      const cleanedResponse = response
        .replace(/```html|```/g, '')
        .replace(/<\/?html>/g, '');

      setLoadingPrompt(prompt.title);
      console.log("responses==>", prompt.title)
      if (response) {
        responses.push({ topic: prompt.title, response: cleanedResponse });
      }
    }
    // Handle responses as needed
    console.log("responses==>", responses)
    setGptData(responses);
    setLoading(false);

  };

  useEffect(() => {
    if (gptData?.length > 0) {
      dispatch(setAssignmentTitle(data))
      navigate('/view-todo-assignment', { state: gptData })
    }
  }, [gptData])

  const initialValues = predefinedPrompts.slice(1, predefinedPrompts?.length - 1).reduce((acc, prompt) => {
    acc[prompt.key] = prompt.value;
    return acc;
  }, {});

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: onSubmit,
    validationSchema: validationSchema,
    enableReinitialize: true,
  });

  const { handleSubmit, errors, values, setFieldValue, touched, setTouched } =
    formik;

  console.log("gptData", data)

  return (
    <div className="create_todo_page sec-padding ">
      {/* {loading && <Loader />} */}

      {loading && <ProgressBar loading={loading} text={loadingPrompt} type="topic" />}
      <div className="cstm_Ctnr">
        <div className="table_wpr p-30 ">
          <div className="create_todo_wpr">
            <button className="unstyled-button" onClick={() => navigate(-1)}>
              <img src={images.Button} alt="button" />
            </button>
            <div className="cmn_hdr mt-10">
              <h3>
                <b>Assignment Plan Generator, the AI chatbot.</b>
              </h3>
            </div>

            <form onSubmit={handleSubmit}>

              <div className="new_form">
                {predefinedPrompts?.map((prompt, index) => (
                  <div
                    className="form-group "
                    key={prompt.id}
                    style={{ width: prompt.width }}
                  >
                    {prompt.inputType === "text" && (
                      <>
                        <div
                          className="input_box"
                          style={{ width: "100%" }}
                        >
                          <label htmlFor={`class-select${index}`}>
                            {prompt.key}
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id={`exampleFormControlInput${index}`}
                            placeholder={prompt.title}
                            name={prompt.key}
                            onChange={(event) => {
                              setData(event.target.value);
                              setFieldValue(prompt.key, event.target.value);
                            }
                            }

                          />
                        </div>
                        {touched[prompt.key] && errors[prompt.key] ? (
                          <div className="err_msg">{errors[prompt.key]}</div>
                        ) : <div style={{ visibility: 'hidden' }} className="err_msg">text</div>}
                      </>
                    )}
                    {prompt.inputType === "textarea" && (
                      <>
                        <div className="input_box">
                          <label htmlFor={`class-select${index}`}>
                            {prompt.key}
                          </label>
                          <textarea
                            className="form-control"
                            id={`exampleFormControlTextarea${index}}`}
                            rows={prompt.row}
                            value={prompt.value}
                            name={prompt.key}
                            placeholder={prompt.title}
                            onChange={(event) => {
                              handleChange(event, index);
                              setFieldValue(prompt.key, event.target.value);
                            }}

                          ></textarea>
                        </div>
                        {touched[prompt.key] && errors[prompt.key] ? (
                          <div className="err_msg">{errors[prompt.key]}</div>
                        ) : <div style={{ visibility: 'hidden' }} className="err_msg">text</div>}

                      </>
                    )}
                    {prompt.inputType === "select" && (
                      <>
                        {/* <div className="input_box">
                          <label htmlFor={`class-select${index}`}>
                            {prompt.key}
                          </label>
                          <input
                            className="form-control"
                            id={`exampleFormControlTextarea${index}}`}
                            rows={prompt.row}
                            value={grade}
                            name={prompt.key}
                            placeholder={prompt.title}
                          // onChange={(event) => {
                          //   handleChange(event, index);
                          //   setFieldValue(prompt.key, event.target.value);
                          // }}

                          />
                        </div>
                        {touched[prompt.key] && errors[prompt.key] ? (
                          <div className="err_msg">{errors[prompt.key]}</div>
                        ) : <div style={{ visibility: 'hidden' }} className="err_msg">text</div>} */}
                        <div className="input_box">
                          <label htmlFor={`class-select${index}`}>
                            {prompt.key}
                          </label>
                          <select
                            className="form-control"
                            id={`exampleFormControlSelect${index}`}
                            data-bs-toggle="collapse"
                            name={prompt.key}
                            onChange={(event) => {
                              setGrade(event.target.value);
                              setFieldValue(prompt.key, event.target.value);
                            }}
                          >
                            <option value="">Select an option</option>
                            {/* {gradeLevels?.map((grade, index) => ( */}
                            <option key={index} value={grade}>
                              {grade}
                            </option>
                            {/* ))} */}
                          </select>
                        </div>
                        {touched[prompt.key] && errors[prompt.key] ? (
                          <div className="err_msg">{errors[prompt.key]}</div>
                        ) : <div style={{ visibility: 'hidden' }} className="err_msg">text</div>}
                      </>
                    )}
                  </div>
                ))}
              </div>

              <button
                type="submit"
                className="common-button"
              // disabled={disable}
              >
                Generate
              </button>

            </form>
          </div>
        </div>
      </div>

    </div >
  );
};
