import React from "react";
import { images } from "../../../constants/ImageImports";
import { APIConstant } from "../../../services/apiConstant";
import { toast } from "react-toastify";

export const ImageUpload = ({
  image,
  setImage,
  imgError,
  setImgErr,
  className,
}) => {
  const allowedFormats = [
    "image/jpeg",
    "image/jpg",
    "image/png",
    "image/svg+xml",
  ];
  const maxImageSize = 2 * 1024 * 1024;
  const imageHandler = (val) => {
    const getImage = val.target.files[0];

    if (
      getImage &&
      allowedFormats.includes(getImage.type) &&
      getImage.size <= maxImageSize
    ) {
      const imgUrl = URL.createObjectURL(getImage);
      setImage({
        src: getImage,
        blob: imgUrl,
      });
      setImgErr(false);
    } else {
      setImgErr(true);
      if (!allowedFormats.includes(getImage.type)) {
        toast.error("Please choose a valid image format (jpg, jpeg, png, svg)");
      } else if (getImage.size > maxImageSize) {
        toast.error("Image size should not exceed 5MB");
      }
    }
  };
  // APIConstant?.MEDIA +

  return (
    <div className={className ? `${className} prf_upload` : "prf_upload"}>
      <img
        className="preview_img"
        src={
          image.preview !== null &&
          image.preview !== undefined &&
          image.preview !== ""
            ? image?.preview
            : image.blob
            ? image?.blob
            : image.src
        }
        crossOrigin="*"
        alt="img"
      />
      {className === "bg-upload" ? "" : <img src={images?.camera} alt="img" />}
      <input
        type="file"
        onChange={imageHandler}
        accept=".jpg, .jpeg, .png, .svg"
      />
      {className !== "bg-upload" && imgError && (
        <p className="error_msg">This Field is Required</p>
      )}
    </div>
  );
};
