import React from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

export const SelectBox = ({
  style,
  value,
  setValue,
  data,
  className,
  label,
  placeholder,
  error,
  setError,
}) => {
  const handleChange = (event) => {
    setValue(event.target.value);
    if(setError){setError(false)}
  };

  return (
    <FormControl
      style={{ ...style }}
      className={className ? `${className} select__bx` : "select__bx"}
    >
      {label && <label>{label}</label>}
      <Select
        value={value}
        onChange={handleChange}
        displayEmpty
        aria-multiselectable
        renderValue={(selected) => {
          if (selected?.length === 0) {
            return <span>{placeholder}</span>;
          }

          return selected;
        }}
        inputProps={{ "aria-label": "Without label" }}
      >
        {data?.map((item, i) => {
          return (
            <MenuItem value={item} key={i}>
              {item}
            </MenuItem>
          );
        })}
      </Select>
      {error && <div className="error_text">Thise Field is required</div>}
    </FormControl>
  );
};
