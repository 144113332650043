import { createSlice } from "@reduxjs/toolkit";

const initialModalState = {
    isLanguage:'english',
    isTone:'',
    type:'short',
    isReferesh:false
}

const voiceModalSlice = createSlice({
    name: "voiceModal",
    initialState: initialModalState,
    reducers: {
        voicelanguageAction(state, action) {
            state.isLanguage = action.payload;
        },
        voiceToneAction(state, action) {
            state.isTone= action.payload;
        },
        voiceTypeAction(state, action) {
            state.type= action.payload;
        },
        voiceRefreshAction(state, action) {
            state.isReferesh=action.payload;
        }
    }
});

export default voiceModalSlice; 
export const {voicelanguageAction, voiceToneAction, voiceTypeAction, voiceRefreshAction} = voiceModalSlice.actions;     
