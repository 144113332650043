import { createSlice } from "@reduxjs/toolkit";

const initialModalState = {
    todoList:[],
    assignmentData:{},
    isPdfStart: false,
    promptList:[],
    pdfData:[],
}

const todoSlice = createSlice({
    name: "todo",
    initialState: initialModalState,
    reducers: {
        todoListAction(state, action) {
            state.todoList = action.payload;
        },
        assignmentDataAction(state, action) {
            state.assignmentData = action.payload;
        },
        pdfStartAction(state, action) {
            state.isPdfStart = action.payload;
        },
        promptListAction(state, action) {
            state.promptList = action.payload;
        },
        pdfDataAction(state, action) {
            state.pdfData = action.payload;
        }
    }
});

export default todoSlice;
export const {todoListAction,assignmentDataAction, pdfStartAction, promptListAction, pdfDataAction} = todoSlice.actions;