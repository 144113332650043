import React, { useState } from "react";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import CommonFormInput from "../../components/common/formInputs/CommonFormInput ";
import { SelectBox } from "../../components/common/formInputs/SelectBox";
import { ImageUpload } from "../../components/common/formInputs/ImageUpload";
import usePost from "../../services/customHook/usePost";
import { APIConstant } from "../../services/apiConstant";
import { useEffect } from "react";
import { images } from "../../constants/ImageImports";
import { ClassSelectBox } from "../../components/common/formInputs/ClassSelectBox";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../../components/common/loader";

const pronouns = ["He/Him", "She/Her", "They/Them", "Other"];

const studentSchema = Yup.object().shape({
  studentname: Yup.string()
    .min(3, "Username should be between (2-40) characters")
    .max(39, "Username should be between (2-40) characters")
    .matches(/^[A-Za-z\s]+$/, "Only alphabets and spaces are allowed")
    .required("Required*"),
  email: Yup.string()
    .email("Email should be valid")
    .required("Required*")
    .matches(
      /^[^\s@]+@[^\s@]+\.(com|ca|io|uk|aus|in)$/,
      "Invalid email format"
    ),
  address: Yup.string().max(150, "Address must not exceed 150 characters."),
  coursename: Yup.string()
    .required("Required*")
    .max(30, "Course name must not exceed 30 characters"),

  phone: Yup.string()
    .length(10, "Phone no. should be of 10 digits")
    .matches(/^[0-9]+$/, "Phone number must contain only digits"),
});

export const StudentEdit = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const { mutateAsync } = usePost();
  const [studentData, setStudentData] = useState({});
  const [classId, setClassId] = useState(studentData?.student?.class_id || "");
  const [errorProfileBg, setErrorProfileBg] = useState(false);
  const [errorProfilePic, setErrorProfilePic] = useState(false);
  const [errorClassName, setErrorClassName] = useState(false);
  const [classList, setClassList] = useState([]);
  const [gender, setGender] = useState(studentData?.gender || "");
  const [errorGender, setErrorGender] = useState(false);
  const [profilePic, setProfilePic] = useState({
    blob: images?.profile_cover,
    src: "",
    preview: "",
  });
  const [profileBg, setProfileBg] = useState({
    blob: images?.bgCover,
    src: "",
    preview: "",
  });
  const [className, setClassName] = useState(
    studentData?.student?.class_id || ""
  );

  const handleStudentDetailPost = async () => {
    try {
      const { payload } = await mutateAsync({
        url: APIConstant?.STUDENT_VIEW,
        payload: {
          id: id,
        },
        isToastDiasble: true,
      });
      setStudentData(payload?.data[0]);
      setGender(payload?.data[0].gender);
      setClassId(payload?.data[0].student?.class_id);
      setProfilePic((prevProfilePic) => ({
        ...prevProfilePic,
        preview: payload?.data[0].photo,
      }));
      setProfileBg((prevProfilePic) => ({
        ...prevProfilePic,
        preview: payload?.data[0].background,
      }));
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    handleClassListPost();
    handleStudentDetailPost();
  }, []);

  useEffect(() => {
    classList?.filter((item) => {
      if (item.class_title === className) {
        setClassId(item._id);
      }
    });
  }, [className, classId]);

  useEffect(() => {
    classList.filter((item) => {
      if (item._id === classId) {
        setClassName(item.class_title);
      }
    });
  }, [classId]);

  const handleSubmit = async (
    values,
    { resetForm, setFieldError, setSubmitting }
  ) => {
    if (classId === "") {
      setErrorClassName("className", "Required*");
    } else if (gender === "") {
      setErrorGender("gender", "Required*");
    } else if (values?.phone?.charAt(0) === "0") {
      toast.error("Please enter valid phone number");
    } else if (
      values?.phone?.charAt(0) === "0" &&
      values?.phone?.charAt(9) === "0"
    ) {
      toast.error("Please enter valid phone number");
    } else if (
      values?.age !== "" &&
      values?.age !== null &&
      values?.age !== undefined &&
      (values?.age < 11 || values?.age > 99)
    ) {
      toast.error("Please enter age between 10 and 100");
    } else {
      setIsLoading(true);
      setSubmitting(true);
      let formdata = new FormData();
      formdata.append("name", values.studentname);
      formdata.append("email", values.email);
      formdata.append("phone", values.phone);
      formdata.append("gender", gender);
      formdata.append("age", values?.age);
      formdata.append("address", values?.address);
      formdata.append("profile_image", profilePic?.src || profilePic?.preview);
      formdata.append("course_name", values?.coursename);
      formdata.append("class_id", classId);
      formdata.append("id", id);
      formdata.append("background", profileBg?.src || profileBg?.preview);
      try {
        const result = await mutateAsync({
          url: APIConstant?.STUDENT_UPDATE,
          payload: formdata,
          contentType: "multipart/form-data",
        });
        if (result?.name === "AxiosError") {
          setSubmitting(false);
          setIsLoading(false);
          toast.error(result?.response?.data?.data);
        } else {
          // toast.success("Student updated successfully");
          setSubmitting(false);
          setIsLoading(false);
          resetForm();
          navigate("/student-list");
        }
      } catch (error) {
        setSubmitting(false);
        setIsLoading(false);
      }
    }
  };

  const handleClassListPost = async () => {
    try {
      const { payload } = await mutateAsync({
        url: APIConstant?.STUDENT_CLASS_LIST,
        payload: {},
        isToastDiasble: true,
      });
      setClassList(payload?.data);
    } catch (error) {}
  };
  return (
    <>
      {isLoading && <Loader />}
      <div className="student_list_page sec-padding ">
        <div className="cstm_Ctnr">
          <div className="tab_wpr">
            <Formik
              initialValues={{
                studentname: studentData?.name,
                email: studentData?.email,
                age: studentData?.age || null,
                address: studentData?.address || "",
                phone: studentData?.phone || "",
                coursename: studentData?.student?.course_name,
              }}
              onSubmit={handleSubmit}
              validationSchema={studentSchema}
              enableReinitialize={true}
            >
              {({ isSubmitting, values, errors }) => (
                <Form>
                  <ImageUpload
                    image={profileBg}
                    setImage={setProfileBg}
                    setImgErr={setErrorProfileBg}
                    imgError={errorProfileBg}
                    className="bg-upload"
                  />
                  <div className="d-flex flex-wrap edit_details mb-30">
                    <ImageUpload
                      image={profilePic}
                      setImage={setProfilePic}
                      setImgErr={setErrorProfilePic}
                      imgError={errorProfilePic}
                      data={studentData?.photo}
                    />
                    <div className="prf_description d-flex justify-end align-center">
                      <div className="btn_group ">
                        <button
                          type="submit"
                          disabled={isSubmitting}
                          className="btn_reset pink_btn "
                        >
                          {isSubmitting ? "Updating..." : "Update"}
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="form_row item2">
                    <CommonFormInput
                      label="Student Name *"
                      type="text"
                      name="studentname"
                      placeholder="Enter name"
                    />
                    <CommonFormInput
                      label="Email *"
                      className="pe-none"
                      type="email"
                      name="email"
                      placeholder="Enter email"
                    />
                    <ClassSelectBox
                      className={"input_wpr"}
                      data={classList}
                      label={"Class Name*"}
                      value={className}
                      error={errorClassName}
                      setError={setErrorClassName}
                      setValue={setClassName}
                      placeholder={"Select Class"}
                    />
                    <CommonFormInput
                      label="Course name *"
                      type="text"
                      name="coursename"
                      placeholder="Enter Course name"
                    />
                    <SelectBox
                      className={"input_wpr"}
                      label={"Pronouns *"}
                      data={pronouns}
                      value={gender}
                      setValue={setGender}
                      error={errorGender}
                      setError={setErrorGender}
                      placeholder={"Select Pronoun"}
                    />
                    <CommonFormInput
                      label="Phone number"
                      type="tel"
                      name="phone"
                      placeholder="Enter phone number"
                    />
                    <CommonFormInput
                      label="Address"
                      type="textarea"
                      name="address"
                      placeholder="Enter address"
                    />
                    <CommonFormInput
                      label="Age "
                      type="number"
                      name="age"
                      placeholder="Enter age"
                    />
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
};
