import React from "react";
import Cookies from "js-cookie";
import { useEffect } from "react";
import { Navigate, Outlet, useNavigate } from "react-router-dom";
import Sidebar from "../components/common/sidebar/Sidebar";
import Header from "../components/common/header/Header";
import "react-toastify/dist/ReactToastify.css";

function Maincontainer() {
  const auth = Cookies.get("teacher_access_token");
  const pathName = window.location.pathname;
  let navigate = useNavigate();

  useEffect(() => {
    if (!auth || auth === "" || auth === undefined) {
      navigate("/login");
    }
  }, [auth, navigate]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathName]);

  return (
    <>
      {auth ? (
        <div className="main_container">
          <Sidebar />
          <div className="ctnt_wpr">
            <Header />
            <Outlet />
          </div>
        </div>
      ) : (
        <Navigate to="/login" />
      )}
    </>
  );
}
export default Maincontainer;
