import React from "react";
import { useParams } from "react-router-dom";
import { StudentTable } from "../student/StudentTable";
import { APIConstant } from "../../services/apiConstant";

export const ClassStudentList = () => {
  const { id } = useParams();
  return (
    <div className="student_list_page sec-padding">
      <div className="cstm_Ctnr">
        {/* Header */}
        <div className="cmn_hdr">
          <h3>
            <b>Student List</b>
          </h3>
        </div>

        {/* Table */}
        <StudentTable
          label={""}
          size={10}
          id={id}
          editDisable={true}
          api={APIConstant?.CLASS_STUDENT_LIST}
          viewLink="/class/student-detail"
        />
      </div>
    </div>
  );
};
