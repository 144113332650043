import React, { useMemo } from "react";
import "./dashboard.css";
import { ClassTable } from "../classManagment/ClassTable";
import { StudentTable } from "../student/StudentTable";
import { APIConstant } from "../../services/apiConstant";

export const Dashboard = () => {
  const studentTableProps = useMemo(() => ({
    label: "Most 10 Recent Students",
    size: 10,
    isPaginationDisable: true,
    isSearchDisable: true,
    isLoaderFalse:true,
    api: APIConstant?.STUDENT_LIST,
  }), []);

  const classTableProps = useMemo(() => ({
    label: "Most 10 Recent Classes",
    size: 10,
    isPaginationDisable: true,
    isSearchDisable: true,
  }), []);

  return (
    <div className="todo-list-page sec-padding min-vh">
      <div className="cstm_Ctnr">
        <div className="cmn_hdr">
          <h3>
            <b>Students List</b>
          </h3>
        </div>
        <StudentTable {...studentTableProps} />

        <div className="cmn_hdr mt-40">
          <h3>
            <b>Classes List</b>
          </h3>
        </div>
        <ClassTable {...classTableProps} />
      </div>
    </div>
  );
};
