import React, { useEffect, useState } from "react";
import { images } from "../../constants/ImageImports";
import usePost from "../../services/customHook/usePost";
import { APIConstant } from "../../services/apiConstant";
import Loader from "../../components/common/loader";

export const TermAndCondition = () => {
  const { mutateAsync } = usePost();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);

  const handlePost = async (item) => {
    try {
      const { payload } = await mutateAsync({
        url: APIConstant?.STATIC,
        payload: {
          page_name: "terms",
        },
        isToastDiasble: true,
      });
      setData(payload?.data?.content);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handlePost();
  }, []);

  return (
    <>
      {loading && <Loader />}
      <div className="privacy-page sec-padding min-vh">
        <div className="cstm_Ctnr">
          <div className="privacy_layout">
            <div className="privacy_heading">
              <div className="privacy_heading_icon">
                <img src={images?.termandcondition} alt="img" />
              </div>
              <h4>Terms & Conditions</h4>
            </div>
            {data?.length > 0 && (
              <div
                className="privacy_content"
                dangerouslySetInnerHTML={{ __html: data }}
              ></div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
