import React, { useEffect } from "react";
import "./auth.css";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AuthLeftComponent } from "../../features/auth/AuthLeftComponent";
import { AuthRightComponent } from "../../features/auth/AuthRightComponent";
import { images } from "../../constants/ImageImports";
import { toast } from "react-toastify";

export const Login = () => {
  const auth = localStorage.getItem("token");
  const [searchParams, setSearchParams] = useSearchParams();
  let navigate = useNavigate();

  useEffect(() => {
    if (searchParams.get("success") === "un-auth") {
      toast.error("Your Account is Blocked");
      navigate("/login");
    } else if (searchParams.get("success") === "true") {
      toast.success("Logout Successfully");
      navigate("/login");
    } else if (searchParams.get("success") === "user-nan") {
      toast.error("User Not Found");
      navigate("/login");
    }else if (!auth || auth === "" || auth === undefined) {
      navigate("/login");
    } else navigate("/");
  }, [auth, navigate]);

  return (
    <div className="auth_wpr">
      <div
        className="auth_inr_wpr"
        style={{ backgroundImage: `url(${images?.loginImage})` }}
      >
        <AuthLeftComponent />
        <AuthRightComponent />
      </div>
    </div>
  );
};
