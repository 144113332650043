import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { images } from "../../constants/ImageImports";
import { SelectBox } from "../../components/common/formInputs/SelectBox";
import { MODAL_TYPE } from "../../redux/constant/reduxConstant";
import { mdActions } from "../../redux/slices/modalSlice";
import Skeleton from "@mui/material/Skeleton";
import axios from "axios";
import PdfGenerator from "../../components/common/pdf/PdfGenerator";
import { toast } from "react-toastify";
import {
  pdfDataAction,
  assignmentDataAction,
  pdfStartAction,
  promptListAction,
  todoListAction,
} from "../../redux/slices/todoSlice";
import { Link, useLocation, useNavigate } from "react-router-dom";
import DatePicker from "../../components/common/DatePicker";
import moment from "moment";
import { useGoogleLogin } from '@react-oauth/google';
import Loader from "../../components/common/loader";
import { useFormik } from "formik";
import * as Yup from "yup";
import DragAndDrop from "./DragAndDrop";
import usePost from "../../services/customHook/usePost";
import { APIConstant } from "../../services/apiConstant";
import { setClassName, setClassSpecficName, setEndDates, setStartDates, setStudents } from "../../redux/slices/newTodoSlice";
import { setAssignmentTitle } from "../../redux/slices/assignmentSlice";

const predefinedPromptsUse = [
  {
    id: 1,
    key: "Questions & Answers File",
    title: "Paste File URL ...",
    // preText: "Suppose you are creating a worksheet on the topic of",
    // centerText: "for",
    // postText: "students.",
    inputType: "text",
    value: "",
    row: "2",
    width: "100%",
  },

];

export const GenerateToDoAssignment = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const postMutation = usePost();
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState('');
  const [fileId, setFileId] = useState('');
  // const location = useLocation();
  const [predefinedPrompts, setPredefinedPrompts] =
    useState(predefinedPromptsUse);
  const [loading, setLoading] = useState(false);
  const [content, setContent] = useState("")
  const [copySuccess, setCopySuccess] = useState('');
  const [selectedFiles, setSelectedFiles] = useState([]);

  const className = useSelector((state) => state.newTodo.className);
  const students = useSelector((state) => state.newTodo.students);
  const getStartDate = useSelector((state) => state.newTodo.startDate);
  const getEndDate = useSelector((state) => state.newTodo.endDate);

  const assignmentTitle = useSelector((state) => state.assignment.assignmentTitle);

  console.log("assignmentTitle", assignmentTitle)

  const onSubmit = async (value) => {
    console.log("onsubmitvalue==>", value)
    try {
      const formData = new FormData();
      formData.append("link", value["Questions & Answers File"]);
      formData.append("start_date", getStartDate);
      formData.append("end_date", getEndDate);
      formData.append("assessment_title", assignmentTitle);
      formData.append("assessment_description", "new Assignment , Please solve");
      formData.append("assessment_type", "As For");
      formData.append("class_id", className);
      formData.append("type", "assignment");
      const studentIdArray = students?.map((obj, i, arr) => {
        return {
          student_id: obj?._id,
        };
      });

      if (Array.isArray(studentIdArray)) {
        studentIdArray.forEach((id, idIndex) => {
          formData.append(`student_id[${idIndex}]`, id.student_id);
        });
      }

      const arrayOfObjects = Object.values(selectedFiles);
      arrayOfObjects?.forEach((obj, index) => {
        formData.append(`todoFile`, obj);
      });

      // formData.append("product_type", "assignmentData?.selectProduct");

      const result = await postMutation.mutate({
        url: APIConstant?.CREATE_TODO,
        payload: formData,
        contentType: "multipart/form-data",
      });

      dispatch(setClassName(''));
      dispatch(setStudents([]));
      dispatch(setStartDates(''));
      dispatch(setEndDates(''));
      dispatch(setClassSpecficName(''));
      dispatch(setAssignmentTitle(''))

      navigate('/create-todo',);
    } catch (error) {
      console.error("Update Failed:", error);
      toast.error("Failed to update profile. Please try again.");
    }
  }


  const initialValues = predefinedPrompts.reduce((acc, prompt) => {
    acc[prompt.key] = prompt.value;
    return acc;
  }, {});

  const validationSchema = Yup.object({
    "Questions & Answers File": Yup.string()
      .nullable()
      .matches(
        /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/,
        'Invalid URL format'
      ),
  });


  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: onSubmit,
    validationSchema: validationSchema,
    enableReinitialize: true,
  });

  const { handleSubmit, errors, values, setFieldValue, touched, setTouched } =
    formik;
  const handleFileChange = (files) => {
    setSelectedFiles((prevFiles) => [...prevFiles, ...Array.from(files)]);
  };

  return (
    <div className="create_todo_page sec-padding ">
      {loading && <Loader />}
      <div className="cstm_Ctnr">

        <button className="unstyled-button" onClick={() => navigate(-1)}  ><img src={images.Button} alt="button" />
        </button>
        <div className="table_wpr p-30 ">
          <div className="create_todo_wpr">
            <div className="cmn_hdr mt-10">
              <h3>
                <b>Import Form URL</b>
              </h3>
            </div>

            <form onSubmit={handleSubmit}>
              <div className="new_form">
                {predefinedPrompts?.map((prompt, index) => (
                  <div
                    className="form-group"
                    key={prompt.id}
                    style={{ width: prompt.width }}
                  >
                    {prompt.inputType === "text" && (
                      <>
                        <div
                          className="input_box"
                          style={{ width: "100%" }}
                        >
                          <label htmlFor={`class-select${index}`}>
                            {prompt.key}
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id={`exampleFormControlInput${index}`}
                            placeholder={prompt.title}
                            name={prompt.key}
                            onChange={(event) => {
                              // setData(event.target.value);
                              setFieldValue([prompt.key], event.target.value);
                            }
                            }

                          />
                        </div>
                        {touched[prompt.key] && errors[prompt.key] ? (
                          <div className="err_msg">{errors[prompt.key]}</div>
                        ) : <div style={{ visibility: 'hidden' }} className="err_msg">text</div>}
                      </>
                    )}
                  </div>
                ))}


              </div>
              <div class="border-container">
                <div class="side-border left-border"></div>
                <div class="center-text">OR</div>
                <div class="side-border right-border"></div>
              </div>
              <div className="drag_upload">

                <h3>
                  <b>Upload New File</b>
                </h3>

                <DragAndDrop width="100%" handleFileChange={handleFileChange} />
              </div>

              <button
                type="submit"
                className="common-button"

              // onClick={handleGenerate}
              // disabled={disable}
              >
                Upload
              </button>
            </form>
          </div>
        </div >
      </div>
    </div >
  );
};

