import { createSlice } from "@reduxjs/toolkit";

const initialTempState = {
    studentDetail: {},
}

const studentSlice = createSlice({
    name: "student",
    initialState: initialTempState,
    reducers: {
        setStudentDetail(state, action) {
            state.studentDetail = action?.payload;
        }
    }
});

export default studentSlice;
export const studentAction  = studentSlice.actions;
