import { createSlice } from '@reduxjs/toolkit';

const apiSlice = (name) => createSlice({
  name,
  initialState: {
    data: null,
    isLoading: false,
    isError: false,
  },
  reducers: {
    setData: (state, action) => {
      state.data = action.payload;
    },
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setError: (state, action) => {
      state.isError = action.payload;
    },
  },
});

export default apiSlice;
