import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { store, persistor } from "./redux/store";
import { GoogleOAuthProvider } from '@react-oauth/google';
import { PersistGate } from "redux-persist/integration/react";


const clientId = '643253060678-l7s5mhev13po9nl2gj570dmisfl24qld.apps.googleusercontent.com';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <Provider store={store}>
    <GoogleOAuthProvider clientId={clientId}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </GoogleOAuthProvider>
  </Provider>
);