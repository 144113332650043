import React, { useState, useEffect } from "react";
import { images } from "../../constants/ImageImports";
import { APIConstant } from "../../services/apiConstant";
import usePost from "../../services/customHook/usePost";
import Loader from "../../components/common/loader";

export const ContactUs = () => {
  const { mutateAsync } = usePost();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});

  const getData = async () => {
    try {
      const { payload } = await mutateAsync({
        url: APIConstant?.CONTACT_US,
        payload: {},
        isToastDiasble: true,
      });
      setData(payload?.data);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const ContactData = () => {
    return (
      <div className="prf_details contact_details">
        <div className="prf_description">
          <div className="prf_fields">
            <div className="prf_field_Item">
              <div className="prf_label">Address</div>
              <div className="prf_text">
                <span className="prf_Icon">
                  <img src={images?.locationIcon} alt="img" />
                </span>
                <div>{data?.address}</div>
              </div>
            </div>
            <div className="prf_field_Item">
              <div className="prf_label">Phone</div>
              <div className="prf_text">
                <span className="prf_Icon">
                  <img src={images?.profilePhoneIcon} alt="img" />
                </span>
                {data?.phone}
              </div>
            </div>
            <div className="prf_field_Item">
              <div className="prf_label">Email</div>
              <div className="prf_text">
                <span className="prf_Icon">
                  <img src={images?.profileMailIcon} alt="img" />
                </span>
                {data?.email}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      {loading && <Loader />}
      <div className="tab_wpr">
        <div className="tab_hdg_wpr">
          <h5 className="tab_hdg">Contact Us</h5>
        </div>
        <ContactData />
      </div>
    </>
  );
};
