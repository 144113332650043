import React, { useState } from "react";
import moment from "moment";

const DatePicker = ({
  id,
  placeholder,
  handleDateChange,
  disabled,
  selectedDate,
  isMinDate,
  minDate,
}) => {
  return (
    <div className="search_wp">
      <label htmlFor="class-select" style={{ color: " #197bbd", lineHeight: "38px", fontSize: "16px", fontWeight: "700", }}>{placeholder}</label>
      <input
        id={id}
        type="date"
        className={selectedDate ? "opacity-zero" : ""}
        // placeholder={placeholder}
        onChange={handleDateChange}
        min={isMinDate && minDate}
        disabled={disabled}
        value={selectedDate}
      // style={{
      // cursor: "text",
      // width: "48%",
      // padding: "10px 20px",
      // background: "#f4f4f4",
      // borderRadius: "20px",
      // fontSize: "14px",
      // borderColor: "#0000",
      // }}
      />
    </div>
  );
};
export default DatePicker;
