import React from "react";
import "./table.css";
import { images } from "../../../constants/ImageImports";
import moment from "moment";

export const TableLessonbox = ({
  data,
  loader,
  setAddStudent,
  addedStudent,
}) => {
  console.log("data1111", data?.data)
  // const studentAddHandler = (id) => {
  //   setAddStudent((prev) => {
  //     if (prev.some((item) => item === id)) {
  //       return prev.filter((item) => item !== id);
  //     } else {
  //       return [...prev, id];
  //     }
  //   });
  // };

  return (
    <div className="table_wpr">
      <div className="table_responsive">
        {data?.data?.length > 0 ? (
          <table className="table">
            <thead>
              <tr>
                <th>Topic</th>
                <th>Grade</th>
                <th>Created Date</th>
                <th>File Link</th>
                {/* <th>Age</th> */}
              </tr>
            </thead>
            <tbody>
              {data?.data?.map((item, i) => {
                console.log("item", item)
                let link = item?.assignment?.lesson?.link || item?.assignment?.lesson?.uploadedLink;

                return (
                  <tr key={item?._id}>
                    <td>
                      <div className="pr_icn_wpr">
                        {/* <span className="select_bx">
                          <input
                            type="checkbox"
                            checked={addedStudent.includes(item?._id)}
                            onChange={() => studentAddHandler(item?._id)}
                          />
                        </span> */}
                        {/* <span className="pr_icn">
                          <img
                            crossOrigin="*"
                            className="circle prft_img"
                            src={
                              item?.photo !== undefined &&
                                item?.photo !== null &&
                                item?.photo !== ""
                                ? item?.photo
                                : images?.profile_cover
                            }
                            alt="img"
                          />
                        </span> */}
                        {/* {item?.name ? item?.name : "N/A"} */}
                        {item?.assignment?.assessment_title}
                      </div>
                    </td>
                    <td>{item?.classDetails?.class_title}</td>
                    <td>{moment(item?.createdAt).format("DD-MM-YYYY")}</td>
                    <td style={{ color: "#0E81CE", cursor: "pointer", textDecoration: "underline" }}>
                      <a href={link} target="_blank" rel="noopener noreferrer" style={{ color: "inherit", textDecoration: "inherit" }}>
                        Link
                      </a>
                    </td>
                    {/* <td style={{ color: "#0E81CE", cursor: "pointer", textDecoration: "underline" }}>{"Link"}</td> */}
                    {/* <td>{item?.age && item?.age !== "null" ? item?.age : "N/A"}</td> */}
                  </tr>
                );
              })}
            </tbody>
          </table>
        ) : (
          <p className="empty_text text-center">
            {loader ? "Data is Fetching..." : "No Data Found"}
          </p>
        )}
      </div>
    </div>
  );
};
