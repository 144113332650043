import React, { useEffect, useState } from "react";
import logo from "../../components/assets/images/logo.png";
import { useFormik } from "formik";
import { resetPasswordSchema } from "../../utils/validation";
import useAuth from "../../services/customHook/useAuth";
import { APIConstant } from "../../services/apiConstant";
import { useSearchParams, useNavigate } from "react-router-dom";
import usePost from "../../services/customHook/usePost";
import { images } from "../../constants/ImageImports";

export const AuthRightComponentReset = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const email = searchParams.get("email");
  const token = searchParams.get("token");
  const [firstEye, showFirstEye] = useState(false);
  const [secondEye, showSecondEye] = useState(false);
  const [isUrlExpire, setIsUrlExpire] = useState(false);
  const { mutateAsync } = usePost();
  const { isLoading } = useAuth();
  const [loading, setLoading] = useState(true);

  const handleExpiryLink = async () => {
    try {
      const { payload } = await mutateAsync({
        url: APIConstant?.CHECK_LINK,
        payload: { email: email, token: token },
        isToastDiasble: true,
      });
    } catch (error) {
      setIsUrlExpire(true);
      setLoading(false);
    }
  };

  useEffect(() => {
    handleExpiryLink();
  }, []);

  const formik = useFormik({
    initialValues: {
      email: email,
      password: "",
      confirmPassword: "",
    },
    validationSchema: resetPasswordSchema,
    onSubmit: async (values) => {
      await handleLogin(values);
    },
  });

  const handleLogin = async (credentials) => {
    try {
      const { payload } = await mutateAsync({
        url: APIConstant?.RESET_PASSWORD,
        payload: { ...credentials, token: token },
      });
      if (payload) {
        navigate("/login");
      }
    } catch (error) {}
  };

  return (
    <div className="auth_rgt">
      {isUrlExpire && !loading ? (
        <div
          style={{
            display: "flex",
            minHeight: "100vh",
            fontSize: "40px",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            fontWeight: "bold",
          }}
        >
          Link has been expired !!
        </div>
      ) : (
        <form onSubmit={formik.handleSubmit}>
          <div className="auth_ctnt">
            <img src={logo} alt="img" />
            <h4>Welcome to R.I.S.E. Academy </h4>
            <h6>Reset Password</h6>
          </div>
          <div className="auth_frm">
            <div className="input_field_Item">
              <div className="input_label">Email</div>
              <div className="input_field">
                <input
                  type="email"
                  disabled={true}
                  placeholder="Enter your email"
                  id="email"
                  {...formik.getFieldProps("email")}
                />
                {formik.touched.email && formik.errors.email ? (
                  <div className="error_msg">{formik.errors.email}</div>
                ) : null}
              </div>
            </div>
            <div className="input_field_Item">
              <div className="input_label">New password</div>
              <div className="input_field icon">
                <input
                  autoComplete={"true"}
                  type={firstEye ? "text" : "password"}
                  placeholder="**************"
                  id="password"
                  {...formik.getFieldProps("password")}
                />
                <img
                  className="cursor_pointer"
                  src={
                    firstEye
                      ? images?.passwordInputLock
                      : images?.passwordInputUnLock
                  }
                  alt="img"
                  onClick={() => showFirstEye(!firstEye)}
                />
              </div>
              {formik.touched.password && formik.errors.password ? (
                <div className="error_msg">{formik.errors.password}</div>
              ) : null}
            </div>
            <div className="input_field_Item">
              <div className="input_label">Confirm new password</div>
              <div className="input_field icon">
                <input
                  autoComplete={"true"}
                  type={secondEye ? "text" : "password"}
                  placeholder="**************"
                  id="confirmPassword"
                  {...formik.getFieldProps("confirmPassword")}
                />
                <img
                  className="cursor_pointer"
                  src={
                    secondEye
                      ? images?.passwordInputLock
                      : images?.passwordInputUnLock
                  }
                  alt="img"
                  onClick={() => showSecondEye(!secondEye)}
                />
              </div>
              {formik.touched.confirmPassword &&
              formik.errors.confirmPassword ? (
                <div className="error_msg">{formik.errors.confirmPassword}</div>
              ) : null}
            </div>
          </div>

          <div className="lgn_btn_wpr">
            <button type="submit">
              {isLoading ? "Submitting..." : "Submit"}
            </button>
          </div>
        </form>
      )}
    </div>
  );
};
