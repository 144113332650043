export const MODAL_TYPE = {
  ADD_TEACHER: "ADD_TEACHER",
  EDIT_TEACHER: "EDIT_TEACHER",
  ADD_NEW_CLASS: "ADD_NEW_CLASS",
  EDIT_CLASS:"EDIT_CLASS",
  ADD_STUDENT: "ADD_STUDENT",
  VOICE_TONE: "VOICE_TONE",
  LOGOUT: "LOGOUT",
  ASSIGNMENT: "ASSIGNMENT",
  SUCCESS: "SUCCESS",
  DELETE: "DELETE",
};
