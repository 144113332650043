import React, { useEffect } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Message = ({ notification }) => {
  return (
    <div>
      <div style={{ fontWeight: "bold", fontSize: "12px" }}>
        {notification.title}
      </div>
    </div>
  );
};

export default Message;

{
  /* <div>{notification.body}</div> */
}
