import React from "react";
import { useDispatch } from "react-redux";
import { images } from "../../constants/ImageImports";
import { mdActions } from "../../redux/slices/modalSlice";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import profileApiSlice from "../../redux/slices/profileSlice";

export const Logout = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const closeModal = () => {
    dispatch(mdActions.hideModal());
  };

  const logout = () => {
    Cookies.remove("teacher_access_token");
    Cookies.remove("");
    setTimeout(() => {
      dispatch(mdActions.hideModal());
      localStorage.clear("token");
      localStorage.clear("");
      dispatch(profileApiSlice.actions.setData({}));
      navigate("/login?success=true");
    }, 100);
  };
  return (
    <div className="mdl_inr logout_mdl img_overlay">
      <div className="cross_icon" onClick={closeModal}>
        <img src={images?.modelCross} alt="img" className="prft_img" />
      </div>
      <div className="mdl_ctnt">
        <h5>Are you sure you want to log out?</h5>
        <div className="btn-group mt-30">
          <button className="btn_reset pink_btn" onClick={logout}>
            Logout
          </button>
          <button className="btn_reset blue_btn" onClick={closeModal}>
            Cancel{" "}
          </button>
        </div>
      </div>
    </div>
  );
};
