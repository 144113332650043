import "./loader.css";

function Loader() {
  return (
    <div className="loader_cmn">
      <div className="spinner" />
    </div>
  );
}

export default Loader;
