import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "./Header.module.css";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import Avatar from "@mui/material/Avatar";
import Fade from "@mui/material/Fade";
import { images } from "../../../constants/ImageImports";
import { Link } from "react-router-dom";
import { mdActions } from "../../../redux/slices/modalSlice";
import { MODAL_TYPE } from "../../../redux/constant/reduxConstant";
import { Modal } from "../../modals";
import { APIConstant } from "../../../services/apiConstant";
import profileSlice from "../../../redux/slices/profileSlice";
import useApi from "../../../services/customHook/useApi";
import {
  assignmentDataAction,
  pdfDataAction,
  pdfStartAction,
  promptListAction,
  todoListAction,
} from "../../../redux/slices/todoSlice";

const Header = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useDispatch();
  const pathName = window.location.pathname;
  const path = pathName.split("/");
  const open = anchorEl;
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const { data } = useSelector((state) => state.profile);
  const postProfileMutation = useApi(profileSlice, APIConstant?.GET_PROFILE);

  const logoutModalHandler = () => {
    dispatch(mdActions.showModal(MODAL_TYPE?.LOGOUT));
  };

  const handlePostProfileData = async () => {
    try {
      const result = await postProfileMutation.mutateAsync({});
    } catch (error) {
      console.error("Error making profile API call:", error);
    }
  };

  useEffect(() => {
    handlePostProfileData();
    if (pathName !== "/create-todo" && pathName !== "/create-todo-assignment") {
      dispatch(pdfStartAction(false));
      dispatch(promptListAction([]));
      dispatch(pdfDataAction([]));
      dispatch(assignmentDataAction({}));
      dispatch(todoListAction([]));
    }
  }, [pathName]);


  const FirstLetter = data?.payload?.data?.name;

  return (
    <>
      <Modal />
      <header className={styles?.main_Hdr}>
        <div className={`cstm_Ctnr ${styles?.hdr_Inr}`}>
          <h3 className={styles?.hdr_Title}>
            {pathName === "/notification"
              ? "Notification"
              : pathName === "/term-and-conditions"
                ? "Terms & Conditions"
                : pathName === "/privacy-policy"
                  ? "Privacy Policy"
                  : pathName === "/todo-list"
                    ? "To Do Management"
                    : pathName === "/student-edit" ||
                      pathName === "/student-list" ||
                      pathName === "/add-student" ||
                      pathName === "/student-detail" ||
                      path[1] === "student-detail" ||
                      path[1] === "student-edit"
                      ? "Student Management"
                      : pathName === "/class-list" || path[1] === "class"
                        ? "Classes Management"
                        : pathName === "/setting"
                          ? "Settings"
                          : pathName === "/create-todo" ||
                            pathName === "/create-todo-assignment" || pathName === "/create-todo-lesson" || pathName === "/create-todo-video" || pathName === "/view-todo-assignment" || pathName === "/view-todo-lesson" || pathName === "/view-todo-video"
                            ? "To-do List Management" : pathName === "/lesson-plan-generator" ? ""
                              : "Dashboard"}
          </h3>
          <Button
            id="logout_Btn"
            aria-controls={open ? "logout_Btn_Menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
            sx={{ padding: 0 }}
          >
            <Avatar
              sx={{ width: "30px", height: "30px" }}
              alt={data?.payload?.data?.name}
              src={data?.payload?.data?.photo}
            />
            <span className="text">{data && data?.payload?.data?.name}</span>
            <img src={images?.path} alt="icon" />
          </Button>
          <Menu
            id="logout_Btn_Menu"
            aria-labelledby="logout_Btn"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            TransitionComponent={Fade}
          >
            <div className={styles?.logout_Btn_Menu}>
              <Link to={"/"} className={styles?.logout_name}>
                {data?.payload?.data?.name}
              </Link>
              <a
                href="mailto:Danielle.Campbell@gmail.com"
                className={styles?.email}
              >
                {data?.payload?.data?.email}
              </a>
              <div
                className={styles?.lgt_btn}
                onClick={() => {
                  logoutModalHandler();
                }}
              >
                <img src={images?.logout} alt="img" />
                logout
              </div>
            </div>
          </Menu>
        </div>
      </header>
    </>
  );
};

export default Header;
