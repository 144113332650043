import React from "react";
import { images } from "../../../constants/ImageImports";
import { APIConstant } from "../../../services/apiConstant";

export const DownloadButton = ({ item }) => {
  let url = item;
  return (
    <a download target="_blank" className="download_btn" href={url}>
      <span>Download</span>
      <span>
        <img src={images?.downloadIcon} alt="image" />
      </span>
    </a>
  );
};
