import { initializeApp } from "firebase/app";
import { getMessaging, onMessage } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyBDzbapYiPKY72L56ZqgFX2_jkmmMWwW9c",
  authDomain: "fcmfirebase-d2913.firebaseapp.com",
  projectId: "fcmfirebase-d2913",
  storageBucket: "fcmfirebase-d2913.appspot.com",
  messagingSenderId: "466571008345",
  appId: "1:466571008345:web:dd4ee056314fbd70bc9448",
  measurementId: "G-5WNBE81JC0",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Messaging service
export const messaging = getMessaging(app);
