import { createSlice } from "@reduxjs/toolkit";

const initialClassState = {
    className: '',
    classSpecficName: '',
    students: [],
    startDate: '',
    endDate: ''
}

const newTodoSlice = createSlice({
    name: "todo",
    initialState: initialClassState,
    reducers: {
        setClassName(state, action) {
            state.className = action.payload;
        },
        setClassSpecficName(state, action) {
            state.classSpecficName = action.payload;
        },
        setStudents(state, action) {
            state.students = action.payload;
        },
        setStartDates(state, action) {
            state.startDate = action.payload;
        },
        setEndDates(state, action) {
            state.endDate = action.payload;
        }
    }
});

export default newTodoSlice;
export const { setClassName, setClassSpecficName, setStudents, setStartDates, setEndDates } = newTodoSlice.actions;
