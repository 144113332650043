import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { APIConstant } from "../apiConstant";

const useAuth = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const baseApi = process.env.REACT_APP_BASE_API;

  const loginApi = async (credentials) => {
    try {
      const response = await axios.post(
        `${baseApi + APIConstant?.ADMIN}`,
        credentials,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response?.status === 200) {
        toast.success("Login Successfully");
        navigate("/");
      } else {
        toast.error("Login Failed");
      }

      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      throw error.response?.data || "An error occurred during login";
    }
  };

  const loginMutation = useMutation(loginApi, {
    onSuccess: (data) => {
      if (data?.payload?.data?.token) {
        Cookies.set("teacher_access_token", data?.payload?.data?.token, {
          expires: 3,
        });
        queryClient.invalidateQueries("user");
      }
    },
    onError: (error) => {},
  });

  const login = async (credentials) => {
    await loginMutation.mutateAsync(credentials);
  };

  const logout = () => {
    localStorage.clear();
    localStorage.removeItem("token");
    queryClient.invalidateQueries("user");
  };

  return {
    login,
    logout,
    isLoading: loginMutation.isLoading,
    isAuthenticated: !!localStorage.getItem("token"),
  };
};

export default useAuth;
