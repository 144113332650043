import { useMutation } from "react-query";
import axios from "axios";
import { useDispatch } from "react-redux";
import Cookies from "js-cookie";

const useApi = (slice, endpoint, contentType) => {
  const dispatch = useDispatch();

  const apiCall = async ({ payload, token = true }) => {
    try {
      dispatch(slice.actions.setLoading(true));
      const authToken = Cookies.get("teacher_access_token");
      const baseApi = process.env.REACT_APP_BASE_API;
      // const baseApi = 'http://localhost:8088/api/v1/';
      let headers;

      if (authToken) {
        headers = {
          "Content-Type": contentType ? contentType : "application/json",
          Authorization: `Bearer ${authToken}`,
        };
      }

      const { data } = await axios.post(baseApi + endpoint, payload, {
        headers,
      });

      dispatch(slice.actions.setLoading(false));
      dispatch(slice.actions.setData(data));

      return data;
    } catch (error) {
      dispatch(slice.actions.setLoading(false));
      dispatch(slice.actions.setError(true));

      console.error(`Error posting data to ${endpoint}:`, error);
      throw error;
    }
  };

  return useMutation((data) => apiCall(data), {
    mutationKey: endpoint,
  });
};

export default useApi;
