import React from 'react';
import "./progressbar.css";

const ProgressBar = ({ text, type }) => {
  return (
    <div className="loader-overlay">
      <div className="loader-linear"></div>
      <div className="loader-bottom-css">{
        type === "topic" ? <>
          {text ? text !== "Grade Level" ? "Generating" + " " + text : "Generating..." : "Generating..."}
        </> : <>{"Generating Question..."}</>


      }</div>
      {/* {text ?
        <div className="loader-bottom-css">{"Generating" + " " + text}</div> :
        <div className="loader-bottom-css" style={{ visibility: 'hidden' }}>text</div>
      } */}
    </div>
  );
};



export default ProgressBar;
