import logo from "../components/assets/images/logoWhite.png";
import minLogo from "../components/assets/images/minLogoWhite.png";
import homeIcon from "../components/assets/images/home.png";
import minimizeIcon from "../components/assets/images/minimize.png";
import notificationIcon from "../components/assets/images/notification.png";
import settingIcon from "../components/assets/images/setting.png";
import termIcon from "../components/assets/images/term.png";
import policyIcon from "../components/assets/images/policy.png";
import todoIcon from "../components/assets/images/todo.png";
import path from "../components/assets/images/Path.png";
import logout from "../components/assets/images/logout.png";
import all from "../components/assets/images/all.svg";
import completed from "../components/assets/images/completed.svg";
import present from "../components/assets/images/present.svg";
import upcoming from "../components/assets/images/upcoming.svg";
import downloadIcon from "../components/assets/images/downloadIcon.svg";
import uploadIcon from "../components/assets/images/uploadIcon.svg";
import pdfIcon from "../components/assets/images/pdfIon.svg";
import uploadFileIcon from "../components/assets/images/uploadFileIcon.svg";
import stepperCheck from "../components/assets/images/stepperCheck.svg";
import notificationBlue from "../components/assets/images/notificationBlue.svg";
import notificationYellow from "../components/assets/images/notificationYellow.svg";
import notificationPink from "../components/assets/images/notificationPink.svg";
import privacy from "../components/assets/images/privacy.svg";
import termandcondition from "../components/assets/images/termandcondition.svg";
import infoIico from "../components/assets/images/infoIico.svg";
import changePasswordTabIcon from "../components/assets/images/changePasswordTabIcon.svg";
import deleteAccoutnIcon from "../components/assets/images/deleteAccoutnIcon.svg";
import contactIcon from "../components/assets/images/contactIcon.svg";
import profileMailIcon from "../components/assets/images/profileMailIcon.svg";
import profilePhoneIcon from "../components/assets/images/profilePhoneIcon.svg";
import profilePicIcon from "../components/assets/images/profilePicIcon.svg";
import camera from "../components/assets/images/camera.svg";
import locationIcon from "../components/assets/images/locationIcon.svg";
import loginImage from "../components/assets/images/loginImage.jpg";
import passwordInputLock from "../components/assets/images/passwordInputLock.svg";
import passwordInputUnLock from "../components/assets/images/passwordInputUnLock.svg";
import authBack from "../components/assets/images/authBack.jpg";
import dashWelcome from "../components/assets/images/dashWelcome.svg";
import statIcon1 from "../components/assets/images/statIcon1.svg";
import statIcon2 from "../components/assets/images/statIcon2.svg";
import statIcon3 from "../components/assets/images/statIcon3.svg";
import studentManagment from "../components/assets/images/studentManagment.svg";
import plus from "../components/assets/images/Plus.svg";
import placeholder from "../components/assets/images/Image.png";
import tableDelete from "../components/assets/images/tableDelete.svg";
import tableEdit from "../components/assets/images/tableEdit.svg";
import tableView from "../components/assets/images/tableView.svg";
import searchIcon from "../components/assets/images/searchIcon.svg";
import bgCover from "../components/assets/images/bg_cover.jpg";
import profile_cover from "../components/assets/images/profile_cover.jpg";
import classManagment from "../components/assets/images/classManagment.svg";
import teacherStatIcon1 from "../components/assets/images/teacherStatIcon1.svg";
import teacherStatIcon2 from "../components/assets/images/teacherStatIcon2.svg";
import teacherStatIcon3 from "../components/assets/images/teacherStatIcon3.svg";
import teacherStatIcon4 from "../components/assets/images/teacherStatIcon4.svg";
import toDoIcon from "../components/assets/images/toDoIcon.svg";
import modelCross from "../components/assets/images/modelCross.svg";
import modalBG from "../components/assets/images/modalBG.svg";
import voiceIcon from "../components/assets/images/voiceIcon.svg";
import emoji1 from "../components/assets/images/emoji/emoji1.svg";
import emoji2 from "../components/assets/images/emoji/emoji2.svg";
import emoji3 from "../components/assets/images/emoji/emoji3.svg";
import emoji4 from "../components/assets/images/emoji/emoji4.svg";
import emoji5 from "../components/assets/images/emoji/emoji5.svg";
import emoji6 from "../components/assets/images/emoji/emoji6.svg";
import datePickerIcon from "../components/assets/images/datePickerIcon.svg";
import generateVoice from "../components/assets/images/generateVoice.svg";
import sendMessage from "../components/assets/images/sendMessage.svg";
import reg1 from "../components/assets/images/reg1.svg";
import reg2 from "../components/assets/images/reg2.svg";
import reg3 from "../components/assets/images/reg3.svg";
import assignment_icon_1 from "../components/assets/images/assignment icon_1.svg";
import lesson_icon_2 from "../components/assets/images/lesson icon_2.svg";
import youtube_icoon_3 from "../components/assets/images/youtube icoon_3.svg";
import Button from "../components/assets/images/Button.svg"
import copy_icon from "../components/assets/images/copy icon.svg";
import google_doc_icon from "../components/assets/images/google doc icon.svg";
import pdf_icon from "../components/assets/images/pdf icon.svg";
import txt_icon from "../components/assets/images/txt icon.svg";

import upload_cloud from "../components/assets/images/upload-cloud-01.svg";

export const images = {
    reg1,
    reg2,
    reg3,
    sendMessage,
    generateVoice,
    datePickerIcon,
    emoji1,
    emoji2,
    emoji3,
    emoji4,
    emoji5,
    emoji6,
    voiceIcon,
    modalBG,
    modelCross,
    toDoIcon,
    teacherStatIcon1,
    teacherStatIcon2,
    teacherStatIcon3,
    teacherStatIcon4,
    classManagment,
    profile_cover,
    bgCover,
    searchIcon,
    tableDelete,
    tableEdit,
    tableView,
    placeholder,
    plus,
    statIcon1,
    statIcon2,
    statIcon3,
    studentManagment,
    dashWelcome,
    authBack,
    passwordInputUnLock,
    passwordInputLock,
    loginImage,
    locationIcon,
    camera,
    profilePicIcon,
    profileMailIcon,
    changePasswordTabIcon,
    profilePhoneIcon,
    deleteAccoutnIcon,
    contactIcon,
    infoIico,
    logo,
    minLogo,
    homeIcon,
    minimizeIcon,
    notificationIcon,
    settingIcon,
    termIcon,
    policyIcon,
    todoIcon,
    path,
    logout,
    all,
    upcoming,
    completed,
    present,
    downloadIcon,
    uploadIcon,
    pdfIcon,
    uploadFileIcon,
    stepperCheck,
    notificationPink,
    notificationYellow,
    notificationBlue,
    termandcondition,
    privacy,
    assignment_icon_1,
    lesson_icon_2,
    youtube_icoon_3,
    Button,
    copy_icon,
    google_doc_icon,
    pdf_icon,
    txt_icon,
    upload_cloud
}