import * as React from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import ListItemText from "@mui/material/ListItemText";
import { ErrorMessage } from "formik";
import { Avatar, MenuItem, FormControl, Select } from "@mui/material";
import { images } from "../../../constants/ImageImports";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export const MultipleSelect = ({
  data,
  val,
  setAction,
  error,
  placeholder,
}) => {
  const handleChange = (event) => {
    let originalArray = event?.target?.value;
    const idMap = new Map();
    originalArray.forEach((obj) => {
      const id = obj._id;
      if (!idMap.has(id)) {
        idMap.set(id, 1);
      } else {
        idMap.set(id, idMap.get(id) + 1);
      }
    });

    const duplicateIds = new Set();
    idMap.forEach((count, id) => {
      if (count > 1) {
        duplicateIds.add(id);
      }
    });

    const resultArray = originalArray.filter(
      (obj) => !duplicateIds.has(obj._id)
    );

    setAction(resultArray);
  };

  return (
    <FormControl
      className={
        data?.length === 0 ? "multi_Select m-0 w-100" : "multi_Select m-0 w-100"
      }
      sx={{ m: 1, width: 300 }}
    >
      <Select
        disabled={data?.length === 0}
        labelId="demo-multiple-checkbox-label"
        id="demo-multiple-checkbox"
        multiple
        displayEmpty
        value={val}
        onChange={handleChange}
        input={<OutlinedInput label="Tag" />}
        renderValue={(selected) => {
          if (selected.length === 0) {
            return <div>{placeholder}</div>;
          }
          return selected.map((item) => item.name).join(", ");
        }}
        MenuProps={MenuProps}
      >
        {data?.length > 0 ? (
          data?.map((obj) => (
            <MenuItem
              key={obj?._id}
              value={obj}
              style={{
                background:
                  val.some((item) => item._id === obj._id) && "#E75A7C",
              }}
            >
              <img
                alt={obj?.name}
                src={
                  obj?.photo !== null &&
                  obj?.photo !== undefined &&
                  obj?.photo !== ""
                    ? obj?.photo
                    : images.profile_cover
                }
                crossOrigin="anonymous"
                style={{
                  borderRadius: "50%",
                  width: 24,
                  height: 24,
                  marginRight: "10px",
                  fontSize: "14px",
                }}
              />
              {obj?.name}
            </MenuItem>
          ))
        ) : (
          <MenuItem disabled className="empty">
            No Student Found in this class
          </MenuItem>
        )}
      </Select>
      {error && <small className="error_text">Thise Field is required</small>}
    </FormControl>
  );
};
