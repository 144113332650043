import React, { useState } from "react";
import logo from "../../components/assets/images/logo.png";
import { useNavigate } from "react-router-dom";
import { images } from "../../constants/ImageImports";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import { authSchema } from "../../utils/validation";
import useAuth from "../../services/customHook/useAuth";
import { APIConstant } from "../../services/apiConstant";

export const AuthRightComponent = () => {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const { login, isLoading, isAuthenticated } = useAuth();
  const [isButtonDisabled, setButtonDisabled] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: authSchema,
    onSubmit: async (values) => {
      await handleLogin(values);
    },
  });

  const handleLogin = async (credentials) => {
    if (isButtonDisabled) {
      return;
    }

    setButtonDisabled(true);
    setTimeout(() => {
      setButtonDisabled(false);
    }, 1000);

    await login(credentials);

    if (isAuthenticated) {
      navigate("/");
    }
  };

  return (
    <div className="auth_rgt">
      <form onSubmit={formik.handleSubmit}>
        <div className="auth_ctnt">
          <img src={logo} alt="img" />
          <h4>Welcome to R.I.S.E. Academy </h4>
          <h6>Teacher Login</h6>
        </div>
        <div className="auth_frm">
          <div className="input_field_Item">
            <div className="input_label">Email</div>
            <div className="input_field">
              <input
                type="email"
                placeholder="Enter your email"
                id="email"
                {...formik.getFieldProps("email")}
              />
              {formik.touched.email && formik.errors.email ? (
                <div className="error_msg">{formik.errors.email}</div>
              ) : null}
            </div>
          </div>
          <div className="input_field_Item">
            <div className="input_label">Password</div>
            <div className="input_field icon">
              <input
                autoComplete={"true"}
                type={show ? "text" : "password"}
                placeholder="**************"
                id="password"
                {...formik.getFieldProps("password")}
              />
              <img
                className="cursor_pointer"
                src={
                  show ? images?.passwordInputLock : images?.passwordInputUnLock
                }
                alt="img"
                onClick={() => setShow(!show)}
              />
            </div>
            {formik.touched.password && formik.errors.password ? (
              <div className="error_msg">{formik.errors.password}</div>
            ) : null}
          </div>
        </div>

        <div className="lgn_btn_wpr">
          <button type="submit">{isLoading ? "Logging in..." : "Login"}</button>
          <Link to="/forgot-password">Forgot Password</Link>
        </div>
      </form>
    </div>
  );
};
