import { useMutation } from "react-query";
import axios from "axios";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import { unAuthorisedUser } from "../apiConstant";
import { G } from "@react-pdf/renderer";

const baseApi = process.env.REACT_APP_BASE_API;
// const baseApi = 'http://192.168.1.12:8080/api/v1/teacher/';

const post = async ({ url, payload, ...props }) => {
  const { isToastDiasble, contentType } = props;
  const authToken = Cookies.get("teacher_access_token");
  let headers;

  if (authToken) {
    headers = {
      "Content-Type": contentType ? contentType : "application/json",
      Authorization: `Bearer ${authToken}`,
    };
  }

  try {
    const { data } = await axios.post(baseApi + url, payload, { headers });
    if (!isToastDiasble) {
      toast.success(data?.message);
    } else {
      // toast.error(data?.msg);
    }

    return data;
  } catch (error) {
    if (unAuthorisedUser.includes(error?.response?.status)) {
      localStorage.removeItem("token");
      Cookies.set("teacher_access_token", "", {
        expires: 3,
      });
      if(error?.response?.status === 401){
        window.location.href = "/login?success=un-auth";
      }else if(error?.response?.status ===  404){
        window.location.href = "/login?success=user-nan";
      }
    } else {
      if (!isToastDiasble) {
        toast.error(error?.response?.data?.data);
      } 
    }
    throw error;
  }
};

const usePost = () => useMutation(post);
export default usePost;
