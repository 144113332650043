import React from "react";
import "./table.css";
import { images } from "../../../constants/ImageImports";

export const TableSelectbox = ({
  data,
  loader,
  setAddStudent,
  addedStudent,
}) => {
  const studentAddHandler = (id) => {
    setAddStudent((prev) => {
      if (prev.some((item) => item === id)) {
        return prev.filter((item) => item !== id);
      } else {
        return [...prev, id];
      }
    });
  };

  return (
    <div className="table_wpr">
      <div className="table_responsive">
        {data?.data?.result?.length > 0 ? (
          <table className="table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Phone number</th>
                <th>Gender</th>
                <th>Age</th>
              </tr>
            </thead>
            <tbody>
              {data?.data?.result?.map((item, i) => {
                return (
                  <tr key={item?._id}>
                    <td>
                      <div className="pr_icn_wpr">
                        <span className="select_bx">
                          <input
                            type="checkbox"
                            checked={addedStudent.includes(item?._id)}
                            onChange={() => studentAddHandler(item?._id)}
                          />
                        </span>
                        <span className="pr_icn">
                          <img
                            crossOrigin="*"
                            className="circle prft_img"
                            src={
                              item?.photo !== undefined &&
                              item?.photo !== null &&
                              item?.photo !== ""
                                ? item?.photo
                                : images?.profile_cover
                            }
                            alt="img"
                          />
                        </span>
                        {item?.name ? item?.name : "N/A"}
                      </div>
                    </td>
                    <td>{item?.email ? item?.email : "N/A"}</td>
                    <td>{item?.phone ? item?.phone : "N/A"}</td>
                    <td>{item?.gender ? item?.gender : "N/A"}</td>
                    <td>{item?.age && item?.age !== "null" ? item?.age : "N/A"}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        ) : (
          <p className="empty_text text-center">
            {loader ? "Data is Fetching..." : "No Data Found"}
          </p>
        )}
      </div>
    </div>
  );
};
