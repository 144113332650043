import React from "react";
import "./viewProfile.css";
import { images } from "../../../constants/ImageImports";
import { Link } from "react-router-dom";

export const ViewProfile = ({ data, type, isEditDisable, id }) => {

  return (
    <section className="viewprofile_sec">
      <div className="vp_cover_img">
        <img
          className="prft_img"
          crossOrigin="*"
          src={
            data?.background !== null &&
            data?.background !== undefined &&
            data?.background !== ""
              ? data?.background || images?.bgCover
              : images?.bgCover
          }
          alt="img"
        />
      </div>
      <div className="vp_person_bx">
        <div className="vp_person_img">
          <img
            crossOrigin="*"
            className="prft_img"
            src={
              data?.photo !== null &&
              data?.photo !== undefined &&
              data?.photo !== ""
                ? data?.photo
                : images?.profile_cover
            }
            alt="img"
          />
        </div>
        <div className="vp_person_wpr">
          <div className="vp_person_info">
            <h4>{data?.name}</h4>
            <p>{data?.email}</p>
          </div>
          <div className="btn_group" style={{ display: "block" }}>
            {isEditDisable ? (
              <Link to={-1} className="btn_reset pink_btn sm">
                Back
              </Link>
            ) : (
              <Link
                to={`/student-edit/${id}`}
                className="btn_reset pink_btn sm"
              >
                Edit
              </Link>
            )}
          </div>
        </div>
      </div>

      <ul className="vp_basic_info">
        <li>
          <b>Mobile Number</b>
          <p>{data?.phone ? data?.phone : "N/A"}</p>
        </li>
        <li>
          <b>Address</b>
          <p> {data?.address ? data?.address : "N/A"}</p>
        </li>
        <li>
          <b>Class Name</b>
          <p>{data?.class_title ? data?.class_title : "N/A"}</p>
        </li>
        <li>
          <b>Course name</b>
          <p>
            {data?.student?.course_name ? data?.student?.course_name : "N/A"}
          </p>
        </li>
        <li>
          <b>Gender</b>
          <p>{data?.gender ? data?.gender : "N/A"}</p>
        </li>
        <li>
          <b>Age12</b>
          <p>{(data?.age !== "null" && data?.age) ? data?.age : "N/A"}</p>
        </li>
      </ul>
    </section>
  );
};
