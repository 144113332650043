import React, { useEffect, useState } from "react";
import { Tabbing } from "../../components/common/tabbing/Tabbing";
import { TableSelectbox } from "../../components/common/table/TableSelectbox";
import usePost from "../../services/customHook/usePost";
import { APIConstant } from "../../services/apiConstant";
import {
  CompletedTask,
  ShowTab,
  UpcomingTask,
  PresentTask,
} from "../../features/todoListTabContent";
import { images } from "../../constants/ImageImports";
import Loader from "../../components/common/loader";

export const TodoList = () => {
  const { mutateAsync } = usePost();
  const [isSelected, setIsSelected] = useState(null);
  const [isClassSelected, setClassSelected] = useState("");
  const [classData, setClassData] = useState([]);
  const [studentData, setStudentData] = useState([]);
  const [studentIDData, setStudentIdData] = useState([]);
  const [all, setAll] = useState(0);
  const [upComing, setUpComing] = useState(0);
  const [current, setCurrent] = useState(0);
  const [past, setPast] = useState(0);
  const [loading, setLoading] = useState(false);

  const tabHandler = (i, student) => {
    setIsSelected(i);
    setClassSelected(student);
    setStudentData([]);
  };

  const handlePost = async (url, payData, isTost, setData) => {
    setLoading(true);
    try {
      const { payload } = await mutateAsync({
        url: url,
        payload: payData,
        isToastDiasble: isTost,
      });
      setData(payload);

      if (APIConstant?.CLASS_LIST && !isClassSelected) {
        setTimeout(() => {
          setClassSelected(classData?.data?.result[0]);
        }, 400);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    let paydata = {
      page: 1,
      search: "",
      pageSize: 100,
    };
    handlePost(APIConstant?.CLASS_LIST, paydata, true, setClassData);
  }, []);

  useEffect(() => {
    let paydata = {
      page: 1,
      search: "",
      pageSize: 100,
      id: isClassSelected?._id,
    };
    handlePost(APIConstant?.CLASS_STUDENT_LIST, paydata, true, setStudentData);
  }, [isClassSelected]);

  const allCount = async () => {
    try {
      const { payload } = await mutateAsync({
        url: APIConstant?.TODO_LIST_BY_CATEGORY,
        payload: {
          todo_type: "all",
          page: 1,
          pageSize: 11,
          student_id: studentIDData,
        },
        isToastDiasble: true,
      });
      setAll(payload?.data?.length);
    } catch (error) { }
  };
  const upComingCount = async () => {
    try {
      const { payload } = await mutateAsync({
        url: APIConstant?.TODO_LIST_BY_CATEGORY,
        payload: {
          todo_type: "upcoming",
          page: 1,
          pageSize: 11,
          student_id: studentIDData,
        },
        isToastDiasble: true,
      });
      setUpComing(payload?.data?.length);
    } catch (error) { }
  };
  const currentCount = async () => {
    try {
      const { payload } = await mutateAsync({
        url: APIConstant?.TODO_LIST_BY_CATEGORY,
        payload: {
          todo_type: "current",
          page: 1,
          pageSize: 11,
          student_id: studentIDData,
        },
        isToastDiasble: true,
      });
      setCurrent(payload?.data?.length);
    } catch (error) { }
  };
  const pastCount = async () => {
    try {
      const { payload } = await mutateAsync({
        url: APIConstant?.TODO_LIST_BY_CATEGORY,
        payload: {
          todo_type: "past",
          page: 1,
          pageSize: 11,
          student_id: studentIDData,
        },
        isToastDiasble: true,
      });

      setPast(payload?.data?.length);
    } catch (error) { }
  };

  useEffect(() => {
    if (studentIDData?.length > 0) {
      allCount();
      upComingCount();
      currentCount();
      pastCount();
    }
  }, [studentIDData]);


  console.log("isClassSelected", isClassSelected)
  console.log("studentIDData", studentIDData)
  console.log("studentData", studentData?.data?.result)
  console.log("all", all)
  console.log("upComing", upComing)
  console.log("current", current)
  console.log("past", past)

  const TodoListTabbing = [
    {
      title: "Show All",
      content: <ShowTab studentIDData={studentIDData} />,
      icon: images?.all,
      task: all,
    },
    {
      title: "Upcoming",
      content: <UpcomingTask studentIDData={studentIDData} />,
      icon: images?.present,
      task: upComing,
    },
    {
      title: "Present To-do",
      content: <PresentTask studentIDData={studentIDData} />,
      icon: images?.upcoming,
      task: current,
    },
    {
      title: "Past To-do List",
      content: <CompletedTask studentIDData={studentIDData} />,
      icon: images?.completed,
      task: past,
    },
  ];

  return (
    <>
      {/* {loading && <Loader />} */}
      <div className="todo-list-page sec-padding min-vh">
        <div className="cstm_Ctnr">
          <div className="cmn_hdr">
            <h3>
              <b>Select Classes</b>
            </h3>
          </div>
          <div className="smpl_tabs">
            <ul
              className={
                studentIDData?.length === 1
                  ? "smpl_tab_btns pe-none op-5"
                  : "smpl_tab_btns"
              }
            >
              {classData?.data?.result?.length > 0 && studentData?.data?.result?.length > 0 &&
                classData?.data?.result?.map((item, i) => {
                  return (
                    <li
                      key={item?._id}
                      className={
                        studentIDData?.length === 0 &&
                          isClassSelected?.length === 0 &&
                          i == 0
                          ? "active"
                          : isClassSelected?._id === item?._id
                            ? "active"
                            : ""
                      }
                      style={{
                        background:
                          isClassSelected?._id === item?._id && "lightgray",
                        cursor:
                          isClassSelected?._id === item?._id
                            ? "not-allowed"
                            : "pointer",
                        color: isClassSelected?._id === item?._id && "black",
                        border: isClassSelected?._id === item?._id && "none",
                      }}
                      onClick={() =>
                        isClassSelected?._id !== item?._id &&
                        tabHandler(i, item)
                      }
                    >
                      {item?.class_title}
                    </li>
                  );
                })}
            </ul>
            <div
              className={
                isSelected === 0
                  ? "smpl_tab_ctnt mt-30 active"
                  : "smpl_tab_ctnt mt-30"
              }
            >
              <div className={studentIDData?.length === 1 && "pe-none op-5"}>
                <TableSelectbox
                  data={studentData}
                  loader={loading}
                  setAddStudent={setStudentIdData}
                  addedStudent={studentIDData}
                />
              </div>

              {studentIDData?.length > 0 && (
                <button
                  className="btn_reset pink_btn filter mt-10"
                  onClick={() => setStudentIdData([])}
                >
                  Clear Filter
                </button>
              )}
            </div>
          </div>
          {studentIDData?.length > 0 && (
            <div className="tab_btn_full mt-40">
              <Tabbing tabs={TodoListTabbing} studentIDData={studentIDData} />
            </div>
          )}
        </div>
      </div>
    </>
  );
};
