import React from "react";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import { useDispatch } from "react-redux";
import { images } from "../../constants/ImageImports";
import CommonFormInput from "../common/formInputs/CommonFormInput ";
import { usernameValidation } from "../../utils/validation";
import { mdActions } from "../../redux/slices/modalSlice";
import { toast } from 'react-toastify';

const addSchema = Yup.object().shape({
    ...usernameValidation.fields
});

export const AddStudent = () => {
    const dispatch = useDispatch();
    const handleSubmit = (values, { setSubmitting }) => {
        // setSubmitting(false);
        toast.success('Added Successfully');
        dispatch(mdActions.hideModal());
    };

    const closeModal = () => {
      dispatch(mdActions.hideModal());
    };
  return (
    <div className="mdl_inr addClass_mdl">
      <div className="cross_icon" onClick={closeModal}>
        <img src={images?.modelCross} alt="img" className="prft_img" />
      </div>
      <div className="mdl_hdr">
        <h4>Add New Student</h4>
      </div>
      <div className="mdl_ctnt">
        <Formik
          initialValues={{
            classname: "",
          }}
          validationSchema={addSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting }) => (
            <Form className="form_row">
              <CommonFormInput
                label="Student Name"
                type="text"
                name="username"
                placeholder="Enter Student Name"
              />
              <button className="btn_reset blue_btn" type="submit">Save</button>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};
