import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ViewProfile } from "../../components/common/viewProfile/ViewProfile";
import { Tabbing } from "../../components/common/tabbing/Tabbing";
import { APIConstant } from "../../services/apiConstant";
import usePost from "../../services/customHook/usePost";
import { images } from "../../constants/ImageImports";
import {
  UpcomingTask,
  CompletedTask,
  ShowTab,
  PresentTask,
} from "../../features/todoListTabContent";

export const StudentView = () => {
  const { mutateAsync } = usePost();
  const [count, setCount] = useState({
    all: "10",
    upcoming: "10",
    current: "10",
    past: "10",
  });
  const { id } = useParams();
  const [studentData, setStudentData] = useState({});
  const [all, setAll] = useState(0);
  const [upComing, setUpComing] = useState(0);
  const [current, setCurrent] = useState(0);
  const [past, setPast] = useState(0);
  const allCount = async () => {
    try {
      const { payload } = await mutateAsync({
        url: APIConstant?.TODO_LIST_BY_CATEGORY,
        payload: {
          todo_type: "all",
          page: 1,
          pageSize: 11,
          student_id: [id],
        },
        isToastDiasble: true,
      });
      setAll(payload?.data?.length);
    } catch (error) {}
  };
  const upComingCount = async () => {
    try {
      const { payload } = await mutateAsync({
        url: APIConstant?.TODO_LIST_BY_CATEGORY,
        payload: {
          todo_type: "upcoming",
          page: 1,
          pageSize: 11,
          student_id: [id],
        },
        isToastDiasble: true,
      });
      setUpComing(payload?.data?.length);
    } catch (error) {}
  };
  const currentCount = async () => {
    try {
      const { payload } = await mutateAsync({
        url: APIConstant?.TODO_LIST_BY_CATEGORY,
        payload: {
          todo_type: "current",
          page: 1,
          pageSize: 11,
          student_id: [id],
        },
        isToastDiasble: true,
      });
      setCurrent(payload?.data?.length);
    } catch (error) {}
  };
  const pastCount = async () => {
    try {
      const { payload } = await mutateAsync({
        url: APIConstant?.TODO_LIST_BY_CATEGORY,
        payload: {
          todo_type: "past",
          page: 1,
          pageSize: 11,
          student_id: [id],
        },
        isToastDiasble: true,
      });
      setPast(payload?.data?.length);
    } catch (error) {}
  };

  useEffect(() => {
    if (id) {
      allCount();
      upComingCount();
      currentCount();
      pastCount();
    }
  }, [id]);

  const handleClassListPost = async () => {
    try {
      const { payload } = await mutateAsync({
        url: APIConstant?.STUDENT_VIEW,
        payload: {
          id: id,
        },
        isToastDiasble: true,
      });
      setStudentData(payload?.data[0]);
    } catch (error) {}
  };

  useEffect(() => {
    handleClassListPost();
  }, []);

  const TodoListTabbing = [
    {
      title: "Show All",
      content: <ShowTab />,
      icon: images?.all,
      task: all,
    },
    {
      title: "Upcoming",
      content: <UpcomingTask />,
      icon: images?.present,
      task: upComing,
    },
    {
      title: "Present To-do",
      content: <PresentTask />,
      icon: images?.upcoming,
      task: current,
    },
    {
      title: "Past To-do List",
      content: <CompletedTask />,
      icon: images?.completed,
      task: past,
    },
  ];

  return (
    <div className="view_profile_page sec-padding">
      <div className="cstm_Ctnr">
        <ViewProfile data={studentData} type={"student"} id={id} />
        <div className="tab_btn_full mt-40">
          <Tabbing tabs={TodoListTabbing} />
        </div>
      </div>
    </div>
  );
};
