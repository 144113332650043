import React from "react";
import { useDispatch } from "react-redux";
import { images } from "../../constants/ImageImports";
import { mdActions } from "../../redux/slices/modalSlice";
import { useNavigate } from "react-router-dom";
import { assignmentDataAction, pdfDataAction, pdfStartAction, promptListAction } from "../../redux/slices/todoSlice";

export const Assignment = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const closeModal = () => {
      dispatch(mdActions.hideModal());
    };

    const clickHandler = () => {
      dispatch(assignmentDataAction(false));
      dispatch(promptListAction([]));
      dispatch(pdfDataAction([]));
      dispatch(pdfStartAction(false));
      dispatch(mdActions.hideModal());
      navigate('/create-todo-assignment');
    }

  return (
    <div className="mdl_inr logout_mdl img_overlay">
      <div className="cross_icon" onClick={closeModal}>
        <img src={images?.modelCross} alt="img" className="prft_img" />
      </div>
      <div className="mdl_ctnt">
        <h5>Please make sure that you've added all task before you create an assignment. </h5>
        <div className="btn-group mt-30">
            <button className="btn_reset pink_btn" onClick={clickHandler }>Ok</button>
            <button className="btn_reset blue_btn" onClick={closeModal}>Cancel </button>
        </div>
      </div>
    </div>
  );
};
