import React from "react";
import { useField, ErrorMessage } from "formik";

const CommonFormInput = ({ label, className, ...props }) => {
  const [field, meta] = useField(props);

  return (
    <div
      className={
        props.type === "textarea"
          ? `input_wpr text_area ${className}`
          : `input_wpr ${className}`
      }
    >
      {label && <label htmlFor={props.id || props.name}>{label}</label>}
      {props.type === "textarea" ? (
        <textarea {...field} {...props} />
      ) : (
        <input
          {...field}
          {...props}
          style={{ backgroundColor: props.disabled && "lightgray" }}
        />
      )}
      {meta.touched && meta.error ? (
        <ErrorMessage name={props.name} component="div" className="error" />
      ) : null}
    </div>
  );
};

export default CommonFormInput;
