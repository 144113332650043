import React from "react";
import "./modal.css";
import { useSelector } from "react-redux";
import { MODAL_TYPE } from "../../redux/constant/reduxConstant";
import { AddClass } from "./AddClass";
import { Logout } from "./Logout";
import { VoiceModal } from "./VoiceModal";
import { AddStudent } from "./AddStudent";
import { Delete } from "./Delete";
import { Assignment } from "./Assignment";
import { EditClass } from "./EditClass";

export const Modal = () => {
  const { type, isModal } = useSelector((state) => state.modal);
  const modalType = () => {
    switch (type) {
      case MODAL_TYPE.ADD_NEW_CLASS:
        return <AddClass />;
      case MODAL_TYPE.ADD_STUDENT:
        return <AddStudent />;
      case MODAL_TYPE.LOGOUT:
        return <Logout />;
      case MODAL_TYPE.VOICE_TONE:
        return <VoiceModal />;
      case MODAL_TYPE.DELETE:
        return <Delete />;
      case MODAL_TYPE.ASSIGNMENT:
        return <Assignment />;
      default:
        return null;
    }
  };

  return <>{isModal ? <div className="cstm_mdl">{modalType()}</div> : ""}</>;
};
